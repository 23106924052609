<template>
  <form-wizard
    color="#5A8DEE"
    :title="null"
    :subtitle="null"
    finish-button-text="Tamamla"
    @on-complete="formSubmitted"
  >
    <tab-content
      title="Temel Bilgiler"
      icon="bx bx-user"
    >
      <b-row>
        <firmaBilgi />
      </b-row>
    </tab-content>
    <tab-content
      title="Adresler"
      icon="bx bx-briefcase"
    >
      <b-row>
        <tabAdresler />
      </b-row>
    </tab-content>
    <tab-content
      title="İstihdam"
      icon="bx bx-group"
    >
      <b-row>
        <tabIstihdam />
      </b-row>
    </tab-content>
    <tab-content
      title="Mesai ve Sosyal Haklar"
      icon="bx bx-group"
    >
      <b-row>
        <tabSosyalHaklar />
      </b-row>
    </tab-content>
    <tab-content
      title="Personel Temin ve Geliştirme"
      icon="bx bx-group"
    >
      <b-row>
        <tabPersonelTemin />
      </b-row>
    </tab-content>
    <tab-content
      title="Staj ve Eğitim İmkanları"
      icon="bx bx-group"
    >
      <b-row>
        <tabStajImkan />
      </b-row>
    </tab-content>
  </form-wizard>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
} from 'bootstrap-vue'
import firmaBilgi from '@/views/islemler/firma/components/FirmaBilgi.vue'
import tabAdresler from '@/views/islemler/firma/components/FirmaTabAdresler.vue'
import tabIstihdam from '@/views/islemler/firma/components/FirmaTabIstihdam.vue'
import tabSosyalHaklar from '@/views/islemler/firma/components/FirmaTabSosyalHaklar.vue'
import tabPersonelTemin from '@/views/islemler/firma/components/FirmaTabPersonelTemin.vue'
import tabStajImkan from '@/views/islemler/firma/components/FirmaTabStajImkan.vue'

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    firmaBilgi,
    tabAdresler,
    tabIstihdam,
    tabSosyalHaklar,
    tabPersonelTemin,
    tabStajImkan,
  },
  methods: {
    formSubmitted() {
      // eslint-disable-next-line
      alert('Form submitted!')
    },
  },
}
</script>
