import request from '@/utils/request'

export function GetFirmAddressAll() {
  return request({
    url: '/api/FirmAddress/GetFirmAddressAll',
    method: 'get',
  })
}

export function GetFirmAddressById(data) {
  return request({
    url: `/api/FirmAddress/GetFirmAddressById/${data}`,
    method: 'get',
  })
}

export function GetFirmAddressByFirmId(firmId) {
  return request({
    url: `/api/FirmAddress/GetFirmAddressByFirmInfoId/${firmId}`,
    method: 'get',
  })
}

export function AddFirmAddress(data) {
  return request({
    url: 'api/FirmAddress/AddFirmAddress',
    method: 'post',
    data,
  })
}

export function UpdateFirmAddress(data) {
  return request({
    url: 'api/FirmAddress/UpdateFirmAddress',
    method: 'put',
    data,
  })
}

export function DeleteFirmAddress(data) {
  return request({
    url: `api/FirmAddress/DeleteFirmAddress?firmAddressId=${data}`,
    method: 'delete',
    data,
  })
}
/*
GetFirmAddress()
GetFirmAddress(int rowCount)
GetFirmAddressById(int firmAddressId)
GetFirmAddressesByFirmInfoId(int firmInfoId)
AddFirmAddress(FirmAddressInsertViewModel addressInsertVm)
UpdateFirmAddress(FirmAddressInsertViewModel addressInsertVm)
DeleteFirmAddress(FirmAddressInsertViewModel addressInsertVm)

*/
