<template>
  <b-form @submit.prevent>
    <b-container
      class="bv-example-row"
      name="cont1"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Müşteri Grubu Yurtiçi "
            label-for="Mg-Yurtici"
          >
            <b-form-input
              id="Mg-Yurtici"
              v-model="firmInfo.firmCustomerGroupDomestic"
              placeholder="Yurtiçi Müşteri Sayısı"
              type="number"
              min="0"
              step="1"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Müşteri Grubu Yurtdışı "
            label-for="Mg-Yurtdisi"
          >
            <b-form-input
              id="Mg-Yurtdisi"
              v-model="firmInfo.firmCustomerGroupAbroad"
              placeholder="Yurtdışı Müşteri Sayısı"
              type="number"
              min="0"
              step="1"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Beyaz Yakalı Personel Sayısı"
            label-for="ps-beyaz"
          >
            <b-form-input
              id="ps-beyaz"
              v-model="firmInfo.firmCountOfStaffWhiteCollar"
              placeholder="Beyaz Yakalı Personel Sayısı"
              type="number"
              min="0"
              step="1"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Mavi Yakalı Personel Sayısı"
            label-for="ps-mavi"
          >
            <b-form-input
              id="ps-mavi"
              v-model="firmInfo.firmCountOfStaffBlueCollar"
              placeholder="Mavi Yakalı Personel Sayısı"
              type="number"
              min="0"
              step="1"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Toplam Şube Saysı"
            label-for="subesay"
          >
            <b-form-input
              id="subesay"
              v-model="firmInfo.firmCountOfTotalBranch"
              placeholder="Toplam Şube Saysı"
              type="number"
              min="0"
              step="1"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label-for="ilsubesay"
            label="İl İçi Şube Sayısı"
          >
            <b-form-input
              id="ilsubesay"
              v-model="firmInfo.firmCountOfBranchInProvince"
              placeholder="İl İçi Şube Sayısı"
              type="number"
              min="0"
              step="1"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </b-form>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BContainer,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import * as apiFirmInfo from '@/api/islemler/firminventory'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BContainer,
  },
  directives: {
    Ripple,
  },
  props: {
    firmId: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  computed: {
    ...mapGetters({
      firmInfo: 'firmInventory/GET_FIRMINFO',
    }),
  },
  watch: {
    firmId() {
      this.getFirmData()
    },
  },
  mounted() {
    this.getFirmData()
  },
  methods: {
    ...mapMutations({
      setFirmInfo: 'firmInventory/SET_FIRMINFO',
    }),
    ...mapActions({
      fetchFirmInfo: 'firmInventory/action_get_firminfo',
    }),
    getFirmData() {
      this.fetchFirmInfo(this.firmId).then()
    },
  },
}
</script>
