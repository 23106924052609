<template>
  <div>
    <b-form>
      <b-row> <!-- 1. Satır -->
        <!-- Ünvan -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Firma Unvanı"
            label-for="firmTitle"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="AwardIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="firmTitle"
                v-model="firmInfo.firmTitle"
                placeholder="Firma Ünvanı"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- firma türü nevi -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Firma Türü"
            label-for="firmType"
          >
            <v-select
              id="firmType"
              v-model="firmInfo.firmType"
              label="firmTypeName"
              :options="optionstur"
              :reduce="item => item.firmTypeName"
            />
          </b-form-group>
        </b-col>
        <!-- web adresi -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Web Adresi"
            label-for="firmWebSite"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="LinkIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="firmWebSite"
                v-model="firmInfo.firmWebSite"
                type="url"
                placeholder="Web Adresiniz"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- Sektörler -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Sektörler"
            label-for="firmSectors"
          >
            <v-select
              id="firmSectors"
              v-model="firmInfo.firmSectors"
              label="sectorName"
              :options="sectoroptions"
              :reduce="item => item.sectorName"
              multiple
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row> <!-- 2. satır -->
        <!-- Faaliyet Alanı -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Faaliyet Alanı"
            label-for="firmActivityArea"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="TrendingUpIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="firmActivityArea"
                v-model="firmInfo.firmActivityArea"
                placeholder="Faaliyet Alanı"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- Telefon -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Telefon-1"
            label-for="firmTelephone1"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="PhoneIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="firmTelephone1"
                v-model="firmInfo.firmTelephone1"
                type="number"
                placeholder="Telefon-1"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- Telefon -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Telefon-2"
            label-for="firmTelephone2"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SmartphoneIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="firmTelephone2"
                v-model="firmInfo.firmTelephone2"
                type="number"
                placeholder="Telefon-2"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- E-posta -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="E-Posta"
            label-for="firmEmail"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="MailIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="firmEmail"
                v-model="firmInfo.firmEmail"
                type="email"
                placeholder="E-Posta"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <div class="row">
        <div class="col-md-9 col-xs-12">
          <b-row> <!-- 3. satır -->
            <!-- Kişi 1 -->
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Yetkili Kisi"
                label-for="vi-kisi1"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="UserIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="vi-kisi1"
                    v-model="firmContactInfo.authorizedPerson"
                    placeholder="Yetkili Kişi"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- Görevi -->
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Görevi"
                label-for="vi-gorev1"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="TargetIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="vi-gorev1"
                    v-model="firmContactInfo.authorizedPersonTask"
                    placeholder="Görevi"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- Telefon -->
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Yetkili Telefon"
                label-for="vi-kisitel1"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SmartphoneIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="vi-kisitel1"
                    v-model="firmContactInfo.authorizedPersonPhoneNumber"
                    type="number"
                    placeholder="Yetkili Telefon"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <!-- E-posta -->
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Yetkili E-Posta"
                label-for="vi-kisiemail1"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="MailIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="vi-kisiemail1"
                    v-model="firmContactInfo.authorizedPersonEMail"
                    type="email"
                    placeholder="Yetkili E-Posta"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row> <!-- 4. satır -->
            <!-- Kişi 2 -->
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="İlgili Kisi"
                label-for="vi-kisi2"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="UserIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="vi-kisi2"
                    v-model="firmContactInfo.relatedPerson"
                    placeholder="İlgili Kişi"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- Görevi -->
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Görevi"
                label-for="vi-gorev2"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="TargetIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="vi-gorev2"
                    v-model="firmContactInfo.relatedPersonTask"
                    placeholder="Görevi"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- Telefon -->
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="İlgili Kişi Telefon"
                label-for="vi-kisitel2"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SmartphoneIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="vi-kisitel2"
                    v-model="firmContactInfo.relatedPersonPhoneNumber"
                    type="number"
                    placeholder="ilgili Kişi Telefon"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <!-- E-posta -->
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="İlgili Kişi E-Posta"
                label-for="vi-kisiemail2"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="MailIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="vi-kisiemail2"
                    v-model="firmContactInfo.relatedPersonEMail"
                    type="email"
                    placeholder="İlgili kişi E-Posta"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row> <!-- 5. satır -->
            <!-- Kişi 3 -->
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Görüşülen Kişi 1"
                label-for="vi-kisi3"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="UserIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="vi-kisi3"
                    v-model="firmContactInfo.responsiblePerson1"
                    placeholder="Görüşülen Kişi 1"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- Görevi -->
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Görevi"
                label-for="vi-gorev3"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="TargetIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="vi-gorev3"
                    v-model="firmContactInfo.responsiblePerson1Task"
                    placeholder="Görevi"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- Telefon -->
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Görüşülen Kişi 1 Telefon"
                label-for="vi-kisitel3"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SmartphoneIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="vi-kisitel3"
                    v-model="firmContactInfo.responsiblePerson1PhoneNumber"
                    type="number"
                    placeholder="Görüşülen Kişi 1 Telefon"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <!-- E-posta -->
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Görüşülen Kişi 1 E-Posta"
                label-for="vi-kisiemail3"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="MailIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="vi-kisiemail3"
                    v-model="firmContactInfo.responsiblePerson1EMail"
                    type="email"
                    placeholder="Görüşülen Kişi 1 E-Posta"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row> <!-- 6. satır -->
            <!-- Kişi 4 -->
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Görüşülen Kişi 2"
                label-for="vi-kisi4"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="UserIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="vi-kisi4"
                    v-model="firmContactInfo.responsiblePerson2"
                    placeholder="Görüşülen Kişi 2"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- Görevi -->
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Görevi"
                label-for="vi-gorev4"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="TargetIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="vi-gorev4"
                    v-model="firmContactInfo.responsiblePerson2Task"
                    placeholder="Görevi"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- Telefon -->
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Görüşülen Kişi 2 Telefon"
                label-for="vi-kisitel4"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SmartphoneIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="vi-kisitel4"
                    v-model="firmContactInfo.responsiblePerson2PhoneNumber"
                    type="number"
                    placeholder="Görüşülen Kişi 2 Telefon"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- E-posta -->
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Görüşülen Kişi 2 E-Posta"
                label-for="vi-kisiemail4"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="MailIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="vi-kisiemail4"
                    v-model="firmContactInfo.responsiblePerson2EMail"
                    type="email"
                    placeholder="Görüşülen Kişi 2 E-Posta"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

        </div>
        <div class="col-md-3 col-xs-12">
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              Kimlik Bilgisi: #{{ firm }}
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col cols="6">
              <b-button
                v-if="(firmId > 0 && firmInfo.authUserId === null)"
                id="userButton"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :disabled="firmInfo.authUserId !== null"
                class="d-flex align-items-center"
                squared
                variant="success"
                @click="OnAddUserClick()"
              >
                <feather-icon
                  icon="UserPlusIcon"
                  class="mr-50"
                />
                <span>Hesap Oluştur</span>
              </b-button>
            </b-col>
            <b-col cols="6">
              <b-button
                ref="btnKaydet"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="d-flex align-items-center"
                squared
                variant="danger"
                @click="OnAddFirmaClick()"
              >
                <feather-icon
                  icon="SaveIcon"
                  class="mr-50"
                />
                <span>Firma Kaydet</span>
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-row>
                <label for="firmaLogo">Firma logosu </label>
              </b-row>
              <b-row class="mt-1">
                <img
                  id="blah"
                  :src="firmInfo.firmLogo === '' ? require('@/assets/images/firmnoImage.png') : firmInfo.firmLogo"
                  width="100px"
                  alt="Firma Logosu"
                >
              </b-row>
              <b-row>
                <b-form-file
                  id="firmaLogo"
                  v-model="firmLogo"
                  class="mt-1"
                  accept=".jpg, .png, .gif"
                  placeholder="Logo seçiniz."
                  browse-text="Gözat"
                  @input="filesizecheck()"
                />
              </b-row>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-form>
  </div>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BInputGroup, BInputGroupPrepend, BButton, BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import * as apiFirmType from '@/api/tanimlar/firmtype'
import * as apiSector from '@/api/tanimlar/sector'
import * as apiUser from '@/api/auth'
import * as apiFirm from '@/api/islemler/firminventory'

import { mapGetters, mapMutations, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BButton,
    vSelect,
    BFormFile,
  },
  directives: {
    Ripple,
  },
  props: {
    firmId: {
      type: Number,
      required: false,
      default: -1,
    },
    userObject: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      optionstur: [],
      sectoroptions: [],
      firmLogo: [],
      logoB64: '',
      firm: -1,
    }
  },

  computed: {
    ...mapGetters({
      firmInfo: 'firmInventory/GET_FIRMINFO',
      firmContactInfo: 'firmInventory/GET_FIRMCONTACTINFO',
      get_FirmLogo: 'firmInventory/GET_LOGO',
    }),
  },
  watch: {
    firmId() {
      this.getFirmData()
    },
    firm() {
      this.getFirmData()
    },
  },
  created() {
    // this.getFirmTypeList()
    // this.getSectorsList()
    // this.getFirmData(() => {
    //   this.getFirmContactData()
    // })
  },
  mounted() {
    this.firm = this.firmId
    this.getFirmTypeList()
    this.getSectorsList()
    this.getFirmData()
  },
  methods: {
    ...mapMutations({
      setFirmInfo: 'firmInventory/SET_FIRMINFO',
      setFirmContactInfo: 'firmInventory/SET_FIRMCONTACTINFO',
    }),
    ...mapActions({
      fetchFirmInfo: 'firmInventory/action_get_firminfo',
      saveFirmInfo: 'firmInventory/action_save_firminfo',
      fetchFirmContactInfo: 'firmInventory/action_get_firmcontactinfo',
      saveFirmContactInfo: 'firmInventory/action_save_firmcontactinfo',
      saveFirmBenefits: 'firmInventory/action_save_firmbenefit',
      saveFirmEmployementInfo: 'firmInventory/action_save_firmEmployementInfo',
      saveFirmEmployementPolicy: 'firmInventory/action_save_firmEmployementPolicy',
      setLogo: 'firmInventory/action_set_firm_logo',
      resetFirmInventory: 'firmInventory/action_reset_firmInventory',
    }),
    getFirmTypeList() {
      apiFirmType.getFirmTypesAll().then(res => {
        this.optionstur = res.data.list
      })
    },
    getSectorsList() {
      apiSector.getSectorsAll().then(secs => {
        this.sectoroptions = secs.data.list
      })
    },
    getFirmData() {
      if (this.firmId < 1) this.resetFirmInventory()
      this.fetchFirmInfo(this.firmId).then(() => {
        this.getFirmContactData()
      })
    },
    getFirmContactData() {
      this.fetchFirmContactInfo(this.firmId).then()
    },
    async OnAddFirmaClick() {
      if (this.userObject.UserId !== 0 && this.userObject.userGroupId === 3) {
        this.firmInfo.authUserId = this.userObject.UserId
      }
      this.$refs.btnKaydet.setAttribute('disabled', 'true')
      await this.saveFirmInfo().then(resp => {
        const firmId = resp
        this.saveFirmContactInfo(firmId).then(() => {
          this.saveFirmBenefits(firmId).then(() => {
            this.saveFirmEmployementInfo(firmId).then(() => {
              this.saveFirmEmployementPolicy(firmId).then(() => {
                apiFirm.CheckValidation(firmId).then()
              })
            })
          })
        })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Firma Bilgileri',
            text: 'Kayıt tamamlandı.',
            icon: 'PlusSquareIcon',
            variant: 'success',
          },
        })
        this.firm = firmId
        this.$refs.btnKaydet.removeAttribute('disabled')
      })
    },
    filesizecheck() {
      const boyut = this.firmLogo.size / 1024
      if (boyut <= 300) {
        const reader = new FileReader()
        reader.onloadend = () => {
          this.firmInfo.firmLogo = reader.result
        }
        reader.readAsDataURL(this.firmLogo)
      }
    },
    OnAddUserClick() {
      const userGroup = {
        username: this.firmInfo.firmEmail,
        password: this.firmInfo.firmTelephone1,
        groupId: 3,
      }
      const auser = {
        username: this.firmInfo.firmEmail,
        password: this.firmInfo.firmTelephone1,
        firstName: this.firmInfo.firmTitle,
        middleName: '',
        lastName: '',
        eMail: this.firmInfo.firmEmail,
        gsm: this.firmInfo.firmTelephone1,
      }
      apiUser.signup(auser).then(res => { // login oluştu
        if (res.data.entity > 0) {
          apiUser.assignUserGroup(userGroup).then(() => { // user gruba kayıt
            // E-posta atılacak
            this.firmInfo.authUserId = res.data.entity
            this.saveFirmInfo()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Firma kullanıcı Hesabı Oluşturma',
                text: 'Firma Hesabı oluşturuldu.',
                icon: 'PlusSquareIcon',
                variant: 'success',
              },
            })
          })
        }
      })
    },
  },
}
</script>

<style lang='scss'>
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
