import request from '@/utils/request'

export function GetAddressTypes() {
  return request({
    url: '/api/AddressType/GetAddressTypes/100',
    method: 'get',
  })
}

export function GetAddressTypeById(data) {
  return request({
    url: `/api/AddressType/GetAddressTypeById/${data}`,
    method: 'get',
  })
}
