<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <b-container
          class="bv-example-row"
          name="cont2"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-container
                class="bv-example-row"
                name="cont3"
              >
                <b-row> <!-- Hafta içi gündüz mesai saatleri -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <label>Hafta içi gündüz mesai saatleri</label>
                  </b-col>
                  <b-col
                    cols="6"
                    md="3"
                  >
                    <b-form-input
                      v-model="firmInfo.firmWeekdayMorningWorkingHoursStart"
                      type="time"
                      name="tm111"
                      step="60"
                    />
                  </b-col>
                  <b-col
                    cols="6"
                    md="3"
                  >
                    <b-form-input
                      v-model="firmInfo.firmWeekdayMorningWorkingHoursEnd"
                      type="time"
                      name="tm112"
                      step="60"
                    />
                  </b-col>
                </b-row>
                <br>
                <b-row> <!-- Öğle Arası -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <label for="tm12">Öğle arası</label>
                  </b-col>
                  <b-col
                    cols="6"
                    md="3"
                  >
                    <b-form-input
                      v-model="firmInfo.firmWeekdayLunchBreakStart"
                      type="time"
                      name="tm121"
                      locale="tr"
                      hide-header
                      class="small"
                    />
                  </b-col>
                  <b-col
                    cols="6"
                    md="3"
                  >
                    <b-form-input
                      v-model="firmInfo.firmWeekdayLunchBreakEnd"
                      type="time"
                      name="tm122"
                      locale="tr"
                      hide-header
                      class="small"
                    />
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
          <br>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-container
                class="bv-example-row"
                name="cont4"
              >
                <b-row> <!-- Hafta sonu gündüz mesai saatleri -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <label>Hafta sonu gündüz mesai saatleri</label>
                  </b-col>
                  <b-col
                    cols="6"
                    md="3"
                  >
                    <b-form-input
                      v-model="firmInfo.firmWeekendMorningWorkingHoursStart"
                      type="time"
                      name="tm211"
                      locale="tr"
                      hide-header
                      class="small"
                    />
                  </b-col>
                  <b-col
                    cols="6"
                    md="3"
                  >
                    <b-form-input
                      v-model="firmInfo.firmWeekendMorningWorkingHoursEnd"
                      type="time"
                      name="tm212"
                      locale="tr"
                      hide-header
                      class="small"
                    />
                  </b-col>
                </b-row>
                <br>
                <b-row> <!-- Öğle Arası -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <label>Öğle arası</label>
                  </b-col>
                  <b-col
                    cols="6"
                    md="3"
                  >
                    <b-form-input
                      v-model="firmInfo.firmWeekendLunchBreakStart"
                      type="time"
                      name="tm221"
                      locale="tr"
                      hide-header
                      class="small"
                    />
                  </b-col>
                  <b-col
                    cols="6"
                    md="3"
                  >
                    <b-form-input
                      v-model="firmInfo.firmWeekendLunchBreakEnd"
                      type="time"
                      name="tm222"
                      locale="tr"
                      hide-header
                      class="small"
                    />
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
          <br>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-container
                class="bv-example-row"
                name="cont5"
              >
                <b-row> <!-- Hafta içi vardiya saatleri -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <label>Hafta içi vardiya saatleri</label>
                  </b-col>
                  <b-col
                    cols="6"
                    md="3"
                  >
                    <b-form-input
                      v-model="firmInfo.firmWeekdayOvertimeStart"
                      type="time"
                      name="tm311"
                      locale="tr"
                      hide-header
                      class="small"
                    />
                  </b-col>
                  <b-col
                    cols="6"
                    md="3"
                  >
                    <b-form-input
                      v-model="firmInfo.firmWeekdayOvertimeEnd"
                      type="time"
                      name="tm312"
                      locale="tr"
                      hide-header
                      class="small"
                    />
                  </b-col>
                </b-row>
                <br>
                <b-row> <!-- Dinlenme Arası -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <label for="tm32">Dinlenme arası</label>
                  </b-col>
                  <b-col
                    cols="6"
                    md="3"
                  >
                    <b-form-input
                      v-model="firmInfo.firmWeekdayOvertimeBreakStart"
                      type="time"
                      name="tm321"
                      locale="tr"
                      hide-header
                      class="small"
                    />
                  </b-col>
                  <b-col
                    cols="6"
                    md="3"
                  >
                    <b-form-input
                      v-model="firmInfo.firmWeekDayOvertimeBreakEnd"
                      type="time"
                      name="tm321"
                      locale="tr"
                      hide-header
                      class="small"
                    />
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
          <br>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-container
                class="bv-example-row"
                name="cont6"
              >
                <b-row> <!-- Hafta sonu vardiya saatleri -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <label>Hafta sonu vardiya saatleri</label>
                  </b-col>
                  <b-col
                    cols="6"
                    md="3"
                  >
                    <b-form-input
                      v-model="firmInfo.firmWeekendOvertimeStart"
                      type="time"
                      name="tm411"
                      locale="tr"
                      hide-header
                      class="small"
                    />
                  </b-col>
                  <b-col
                    cols="6"
                    md="3"
                  >
                    <b-form-input
                      v-model="firmInfo.firmWeekendOvertimeEnd"
                      type="time"
                      name="tm412"
                      locale="tr"
                      hide-header
                      class="small"
                    />
                  </b-col>
                </b-row>
                <br>
                <b-row> <!-- Dinlenme arası -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <label>Dinlenme arası</label>
                  </b-col>
                  <b-col
                    cols="6"
                    md="3"
                  >
                    <b-form-input
                      v-model="firmInfo.firmWeekendOvertimeBreakStart"
                      type="time"
                      name="tm421"
                      locale="tr"
                      hide-header
                      class="small"
                    />
                  </b-col>
                  <b-col
                    cols="6"
                    md="3"
                  >
                    <b-form-input
                      v-model="firmInfo.firmWeekendOvertimeBreakEnd"
                      type="time"
                      name="tm422"
                      locale="tr"
                      hide-header
                      class="small"
                    />
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <b-container
          class="bv-example-row"
          name="cont3"
        >
          <b-row>
            <div class="">
              <b-form-group>
                <p class="fs-3">
                  <b>Ulaşım imkanları.</b>
                </p>
                <b-form-radio-group
                  v-model="selecteds01"
                  :options="optionss01"
                />
              </b-form-group>
            </div>
          </b-row>
          <b-row>
            <div class="">
              <b-form-group>
                <p class="fs-3">
                  <b>Yemek imkanları.</b>
                </p>
                <b-form-radio-group
                  v-model="selecteds02"
                  :options="optionss02"
                />
              </b-form-group>
            </div>
          </b-row>
          <b-row>
            <b-row>
              <b-col>
                <p class="fs-3">
                  <b>Diğer Sosyal İmkanlar</b>
                </p>
                <b-row>
                  <b-col
                    cols="9"
                    md="9"
                  >
                    <b-form-group>
                      <b-form-checkbox-group
                        id="checkbox-group-1"
                        v-model="selectedDsi"
                        :options="optionsdsi"
                        name="flavour-1"
                        class="demo-inline-spacing"
                      />
                      <br>
                      <b-form-input
                        id="dsi9"
                        v-model="firmBenefits.socialOtherDescription"
                        name="DigerInput"
                        :disabled="!selectedDsi.includes('dsi8')"
                        placeholder="Açıklayınız."
                        size="sm"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-row>
        </b-container>
      </b-col>
    </b-row>

  </div>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import { BCol, BRow, BFormRadioGroup, BFormInput, BFormCheckboxGroup, BFormGroup, BContainer } from 'bootstrap-vue'
// import * as apiFirmInfo from '@/api/islemler/firminventory'
// import * as apiBenefits from '@/api/islemler/FirmBenefits'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  components: {
    BCol,
    BRow,
    BFormGroup,
    BFormRadioGroup,
    BFormInput,
    BFormCheckboxGroup,
    BContainer,
  },
  props: {
    firmId: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  data() {
    return {
      selectedDsi: [],
      optionsdsi: [
        { text: 'İkramiye', value: 'dsi1' },
        { text: 'Çocuk ve Aile yardımı', value: 'dsi2' },
        { text: 'Yakacak yardımı', value: 'dsi3' },
        { text: 'Eğitim yardımı', value: 'dsi4' },
        { text: 'Bireysel emeklilik', value: 'dsi5' },
        { text: 'Hayat sigortası', value: 'dsi6' },
        { text: 'Yok', value: 'dsi7' },
        { text: 'Diğer seçenekleri açıklayınız', value: 'dsi8' },
      ],
      matched: false,
      tm11: '',
      tm12: '',
      tm21: '',
      tm22: '',
      tm31: '',
      tm32: '',
      tm41: '',
      tm42: '',
      selecteds01: 's011',
      optionss01: [
        { html: '<p style="font-size:12px;">Servis var.</p>', value: 's011', disabled: false },
        { html: '<p style="font-size:12px;">Yol yardımı var.</p>', value: 's012', disabled: false },
        { html: '<p style="font-size:12px;">Servis yada yol yardımı var.</p>', value: 's013', disabled: false },
        { html: '<p style="font-size:12px;">Anlaşmaya bağlı.</p>', value: 's014', disabled: false },
        { html: '<p style="font-size:12px;">Yok.</p>', value: 's015', disabled: false },
      ],
      selecteds02: 's021',
      optionss02: [
        { html: '<p style="font-size:12px;">Şirkette karşılanır.</p>', value: 's021', disabled: false },
        { html: '<p style="font-size:12px;">Ticket verilir.</p>', value: 's022', disabled: false },
        { html: '<p style="font-size:12px;">Şirket yada Ticket verilir.</p>', value: 's023', disabled: false },
        { html: '<p style="font-size:12px;">Ücret olarak ödenir.</p>', value: 's014', disabled: false },
        { html: '<p style="font-size:12px;">Yok.</p>', value: 's015', disabled: false },
      ],
    }
  },
  computed: {
    ...mapGetters({
      firmInfo: 'firmInventory/GET_FIRMINFO',
      firmBenefits: 'firmInventory/GET_FIRMBENEFIT',
    }),
  },
  watch: {
    selecteds01() {
      this.firmBenefits.transportationVehicle = this.selecteds01 === 's011'
      this.firmBenefits.transportationSurcharge = this.selecteds01 === 's012'
      this.firmBenefits.transportationVehicleOrSurcharge = this.selecteds01 === 's013'
      this.firmBenefits.transportationDependsOnContract = this.selecteds01 === 'S014'
      this.firmBenefits.transportationUnavailable = this.selecteds01 === 's015'
    },
    selecteds02() {
      this.firmBenefits.mealProvidedInCompany = this.selecteds02 === 's021'
      this.firmBenefits.mealByTicket = this.selecteds02 === 's022'
      this.firmBenefits.mealInCompanyOrTicket = this.selecteds02 === 's023'
      this.firmBenefits.mealSurcharge = this.selecteds02 === 's024'
      this.firmBenefits.mealUnavailable = this.selecteds02 === 's025'
    },
    selectedDsi() {
      this.firmBenefits.socialBonusPayment = this.selectedDsi.includes('dsi1')
      this.firmBenefits.socialChildBenefit = this.selectedDsi.includes('dsi2')
      this.firmBenefits.socialHeatingAid = this.selectedDsi.includes('dsi3')
      this.firmBenefits.socialEducationalAid = this.selectedDsi.includes('dsi4')
      this.firmBenefits.socialIndividualPension = this.selectedDsi.includes('dsi5')
      this.firmBenefits.socialLifeInsurance = this.selectedDsi.includes('dsi6')
      this.firmBenefits.socialUnavailable = this.selectedDsi.includes('dsi7')
      this.firmBenefits.socialOther = this.selectedDsi.includes('dsi8')
    },
  },
  mounted() {
    this.getFirmData()
  },
  methods: {
    ...mapMutations({
      setFirmInfo: 'firmInventory/SET_FIRMINFO',
      setFirmBenefit: 'firmInventory/SET_FIRMBENEFIT',
    }),
    ...mapActions({
      fetchFirmInfo: 'firmInventory/action_get_firminfo',
      fetchFirmBenefit: 'firmInventory/action_get_firmbenefit',
    }),
    getFirmData() {
      this.fetchFirmInfo(this.firmId).then(() => {
        this.getFirmBenefits()
      })
    },
    getFirmBenefits() {
      this.fetchFirmBenefit(this.firmId).then(() => {
        if (this.firmBenefits.transportationVehicle) this.selecteds01 = 's011'
        if (this.firmBenefits.transportationSurcharge) this.selecteds01 = 's012'
        if (this.firmBenefits.transportationVehicleOrSurcharge) this.selecteds01 = 's013'
        if (this.firmBenefits.transportationDependsOnContract) this.selecteds01 = 's014'
        if (this.firmBenefits.transportationUnavailable) this.selecteds01 = 's015'

        if (this.firmBenefits.mealProvidedInCompany) this.selecteds02 = 's021'
        if (this.firmBenefits.mealByTicket) this.selecteds02 = 's022'
        if (this.firmBenefits.mealInCompanyOrTicket) this.selecteds02 = 's023'
        if (this.firmBenefits.mealSurcharge) this.selecteds02 = 's024'
        if (this.firmBenefits.mealUnavailable) this.selecteds02 = 's025'

        if (this.firmBenefits.socialBonusPayment) this.selectedDsi.push('dsi1')
        if (this.firmBenefits.socialChildBenefit) this.selectedDsi.push('dsi2')
        if (this.firmBenefits.socialHeatingAid) this.selectedDsi.push('dsi3')
        if (this.firmBenefits.socialEducationalAid) this.selectedDsi.push('dsi4')
        if (this.firmBenefits.socialIndividualPension) this.selectedDsi.push('dsi5')
        if (this.firmBenefits.socialLifeInsurance) this.selectedDsi.push('dsi6')
        if (this.firmBenefits.socialUnavailable) this.selectedDsi.push('dsi7')
        if (this.firmBenefits.socialOther) this.selectedDsi.push('dsi8')
      })
    },
  },
}
</script>
