import request from '@/utils/request'

export function getSectorById(data) {
  return request({
    url: `/api/Sector/GetSectorById/${data}`,
    method: 'get',
  })
}

export function getSectorsAll() {
  return request({
    url: '/api/Sector/GetSectorsAll',
    method: 'get',
  })
}

export function addSector(data) {
  return request({
    url: '/api/Sector/AddSector',
    method: 'post',
    data,
  })
}

export function updateSector(data) {
  return request({
    url: '/api/Sector/UpdateSector',
    method: 'put',
    data,
  })
}
export function deleteSector(data) {
  return request({
    url: `/api/Sector/DeleteSector?sectorId=${data}`,
    method: 'delete',
  })
}
