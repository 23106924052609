<template>
  <b-tabs class="card-footer">
    <b-tab>
      <template #title>
        <feather-icon icon="HomeIcon" />
        <span>İş ve İstihdam Bilgileri</span>
      </template>
      <isBilgileri :firm-id="firmId" />
    </b-tab>
    <b-tab active>
      <template #title>
        <feather-icon icon="ToolIcon" />
        <span>İstihdam Bilgileri</span>
      </template>
      <istihdamBilgileri :firm-id="firmId" />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="EyeOffIcon" />
        <span>istihdam Politikaları</span>
      </template>
      <istihdamPolitikalari :firm-id="firmId" />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import isBilgileri from '@/views/islemler/firma/components/firmaIstihdamTabs/IsBilgileri.vue'
import istihdamBilgileri from '@/views/islemler/firma/components/firmaIstihdamTabs/IstihdamBilgileri.vue'
import istihdamPolitikalari from '@/views/islemler/firma/components/firmaIstihdamTabs/IstihdamPolitikalari.vue'

export default {
  components: {
    BTabs,
    BTab,
    isBilgileri,
    istihdamBilgileri,
    istihdamPolitikalari,
  },
  props: {
    firmId: {
      type: Number,
      required: false,
      default: -1,
    },
  },
}
</script>
