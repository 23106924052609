import request from '@/utils/request'

export function getStaffEducations() {
  return request({
    url: '/api/StaffEducation/GetStaffEducations',
    method: 'get',
  })
}
export function getStaffEducationById(data) {
  return request({
    url: `/api/StaffEducation/GetStaffEducationById/${data}`,
    method: 'get',
  })
}
export function addStaffEducation(data) {
  return request({
    url: '/api/StaffEducation/AddStaffEducation',
    method: 'post',
    data,
  })
}
export function updateStaffEducation(data) {
  return request({
    url: '/api/StaffEducation/UpdateStaffEducation',
    method: 'put',
    data,
  })
}
export function deleteStaffEducation(data) {
  return request({
    url: `/api/StaffEducation/DeleteStaffEducation?staffEducationId=${data}`,
    method: 'delete',
  })
}
