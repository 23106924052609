import request from '@/utils/request'

export function GetAnlasamamaNedenleri() {
  return request({
    url: '/api/DisagreementReason/GetDisagreementReasons',
    method: 'get',
  })
}

export function AddAnlasamamaNedenleri(data) {
  return request({
    url: '/api/AnlasamamaNedenleri/AddAnlasamamaNedenleri',
    method: 'post',
    data,
  })
}

export function UpdateAnlasamamaNedenleri(data) {
  return request({
    url: '/api/AnlasamamaNedenleri/UpdateAnlasamamaNedenleri',
    method: 'put',
    data,
  })
}

export function DeleteAnlasamamaNedenleri(data) {
  return request({
    url: '/api/AnlasamamaNedenleri/DeleteAnlasamamaNedenleri',
    method: 'delete',
    data,
  })
}
