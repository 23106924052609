import request from '@/utils/request'

export function getPersonelBulmaZorlugu() {
  return request({
    url: '/api/StaffFindingDifficulty/GetStaffFindingDifficulties',
    method: 'get',
  })
}
export function GetPersonelBulmaZorluguById(data) {
  return request({
    url: `/api/StaffFindingDifficulty/GetStaffFindingDifficultyById/${data}`,
    method: 'get',
  })
}
