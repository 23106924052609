import request from '@/utils/request'

export function GetPersonelPozisyonList() {
  return request({
    url: '/api/StaffPosition/GetStaffPositions',
    method: 'get',
  })
}

export function GetPersonelPozisyonById(data) {
  return request({
    url: `/api/StaffPosition/GetStaffPositionById/${data}`,
    method: 'get',
  })
}

export function AddPersonelPozisyon(data) {
  return request({
    url: '/api/StaffPosition/AddStaffPosition',
    method: 'post',
    data,
  })
}

export function UpdatePersonelPozisyon(data) {
  return request({
    url: '/api/StaffPosition/UpdateStaffPosition',
    method: 'put',
    data,
  })
}

export function DeletePersonelPozisyon(data) {
  return request({
    url: `/api/StaffPosition/DeleteStaffPosition?staffPositionId=${data}`,
    method: 'delete',
  })
}
