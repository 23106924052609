<template>
  <div>
    <b-row>
      <b-col cols="12">
        <p class="fs-3">
          Üniversite ve meslek okullarının ilgili bölümlerinden kısa dönem stajer kabul edebilirim
        </p>
        <b-form-radio-group
          v-model="selectkstaj"
          :options="kstajoptions"
        />
        <p class="fs-3">
          <b>Kabul edeceğiniz stajer özelliklerini belirtiniz. (Bu soru sadece 4. veya 5. şıkkı seçenlere sorulacaktır.)</b>
        </p>
        <b-row>
          <b-col
            cols="12"
            md="2"
          >
            <b-form-checkbox
              v-model="chkeml"
              class="mt-2"
              :checked="chkeml"
              :disabled="selectkstaj=='ks03' || selectkstaj=='ks02' || selectkstaj=='ks01' || selectkstaj=='ks00'"
            >
              <p
                style="font-size:14px;"
                class="mr-3"
              >
                Meslek Lisesi Stajeri
              </p>
            </b-form-checkbox>
          </b-col>
          <b-col
            cols="12"
            md="8"
          >
            <b-form-group>
              <label for="bolum1">Bölüm</label>
              <b-form-input
                id="bolum1"
                v-model="firmEmployeInfo.vocationalTraineeBranch"
                placeholder="Açıklayınız."
                size="sm"
                :disabled="!chkeml || selectkstaj=='ks03' || selectkstaj=='ks02' || selectkstaj=='ks01' || selectkstaj=='ks00'"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="2"
          >
            <b-form-group>
              <label for="bolumsay1">Sayı</label>
              <b-form-input
                id="bolumsay1"
                v-model="firmEmployeInfo.vocationalTraineeCount"
                type="number"
                size="sm"
                :disabled="!chkeml || selectkstaj=='ks03' || selectkstaj=='ks02' || selectkstaj=='ks01' || selectkstaj=='ks00'"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="2"
          >
            <b-form-checkbox
              v-model="chkmyo"
              class="mt-2"
              :checked="chkmyo"
              :disabled="selectkstaj=='ks03' || selectkstaj=='ks02' || selectkstaj=='ks01' || selectkstaj=='ks00'"
            >
              <p
                style="font-size:14px;"
                class="mr-3"
              >
                Meslek Yüksek Okulu
              </p>
            </b-form-checkbox>
          </b-col>
          <b-col
            cols="12"
            md="8"
          >
            <b-form-group>
              <label for="bolum2">Bölüm</label>
              <b-form-input
                id="bolum2"
                v-model="firmEmployeInfo.associateTraineeBranch"
                placeholder="Açıklayınız."
                size="sm"
                :disabled="!chkmyo || selectkstaj=='ks03' || selectkstaj=='ks02' || selectkstaj=='ks01' || selectkstaj=='ks00'"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="2"
          >
            <b-form-group>
              <label for="bolumsay2">Sayı</label>
              <b-form-input
                id="bolumsay2"
                v-model="firmEmployeInfo.associateTraineeCount"
                type="number"
                size="sm"
                :disabled="!chkmyo || selectkstaj=='ks03' || selectkstaj=='ks02' || selectkstaj=='ks01' || selectkstaj=='ks00'"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="2"
          >
            <b-form-checkbox
              v-model="chkunv"
              class="mt-2"
              :checked="chkunv"
              :disabled="selectkstaj=='ks03' || selectkstaj=='ks02' || selectkstaj=='ks01' || selectkstaj=='ks00'"
            >
              <p
                style="font-size:14px;"
                class="mr-3"
              >
                Üniversite Stajeri
              </p>
            </b-form-checkbox>
          </b-col>
          <b-col
            cols="12"
            md="8"
          >
            <b-form-group>
              <label for="bolum3">Bölüm</label>
              <b-form-input
                id="bolum3"
                v-model="firmEmployeInfo.bachelorTraineeBranch"
                placeholder="Açıklayınız."
                size="sm"
                :disabled="!chkunv || selectkstaj=='ks03' || selectkstaj=='ks02' || selectkstaj=='ks01' || selectkstaj=='ks00'"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="2"
          >
            <b-form-group>
              <label for="bolumsay3">Sayı</label>
              <b-form-input
                id="bolumsay3"
                v-model="firmEmployeInfo.bachelorTraineeCount"
                type="number"
                size="sm"
                :disabled="!chkunv || selectkstaj=='ks03' || selectkstaj=='ks02' || selectkstaj=='ks01' || selectkstaj=='ks00'"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col cols="12">
        <p class="fs-3">
          Üniversite ve meslek okullarının uzun dönem uygulamalı eğitimlerinde destek verebilirim.
        </p>
        <b-form-radio-group
          v-model="selectanket2"
          :options="anket2options"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <p class="fs-3">
          İŞKUR ve/veya Belediyenin istihdam garantili eğitim uygulamalarına katılırım.
        </p>
        <b-form-radio-group
          v-model="selectanket3"
          :options="anket2options"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <p class="fs-3">
          Belediyenin düzenleyeceği mesleki gelişim eğitimlerine kendi personelimi gönderirim.
        </p>
        <b-form-radio-group
          v-model="selectanket4"
          :options="anket2options"
        />
      </b-col>
    </b-row>

  </div>
</template>

<script>

import {
  BCol, BRow, BFormRadioGroup, BFormInput, BFormCheckbox, BFormGroup,
} from 'bootstrap-vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    BCol,
    BRow,
    BFormCheckbox,
    BFormInput,
    BFormRadioGroup,
    BFormGroup,
  },
  props: {
    firmId: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  data() {
    return {
      chkeml: false,
      chkmyo: false,
      chkunv: false,
      selectkstaj: '',
      kstajoptions: [
        { html: '<p style="font-size:12px;">Kesinlikle katılmıyorum.</p>', value: 'ks01', disabled: false },
        { html: '<p style="font-size:12px;">Katılmıyorum.</p>', value: 'ks02', disabled: false },
        { html: '<p style="font-size:12px;">Kararsızım.</p>', value: 'ks03', disabled: false },
        { html: '<p style="font-size:12px;">Katılıyorum.</p>', value: 'ks04', disabled: false },
        { html: '<p style="font-size:12px;">Kesinlikle katılıyorum.</p>', value: 'ks05', disabled: false },
      ],
      selectanket2: 'c01',
      selectanket3: 'c01',
      selectanket4: 'c01',
      anket2options: [
        { html: '<p style="font-size:12px;">Kesinlikle katılmıyorum.</p>', value: 'c01', disabled: false },
        { html: '<p style="font-size:12px;">Katılmıyorum.</p>', value: 'c02', disabled: false },
        { html: '<p style="font-size:12px;">Kararsızım.</p>', value: 'c03', disabled: false },
        { html: '<p style="font-size:12px;">Katılıyorum.</p>', value: 'c04', disabled: false },
        { html: '<p style="font-size:12px;">Kesinlikle katılıyorum.</p>', value: 'c05', disabled: false },
      ],
    }
  },
  computed: {
    ...mapGetters({
      firmEmployeInfo: 'firmInventory/GET_FIRMEMPLOYEMENTINFO',
    }),
  },
  watch: {
    selectkstaj() {
      this.firmEmployeInfo.acceptTrainee = this.selectkstaj.substring(this.selectkstaj.length - 1, this.selectkstaj.length)
    },
    chkeml() {
      this.firmEmployeInfo.acceptVocationalTrainee = this.chkeml
    },
    chkmyo() {
      this.firmEmployeInfo.acceptAssociateTrainee = this.chkmyo
    },
    chkunv() {
      this.firmEmployeInfo.acceptBachelorTrainee = this.chkunv
    },
    selectanket2() {
      this.firmEmployeInfo.acceptLongTermTrainee = this.selectanket2.substring(this.selectanket2.length - 1, this.selectanket2.length)
    },
    selectanket3() {
      this.firmEmployeInfo.iskurApps = this.selectanket3.substring(this.selectanket3.length - 1, this.selectanket3.length)
    },
    selectanket4() {
      this.firmEmployeInfo.joinLessons = this.selectanket4.substring(this.selectanket4.length - 1, this.selectanket4.length)
    },
    firmId() {
      this.getIsthidamByFirmId()
    },
  },
  mounted() {
    this.getIsthidamByFirmId()
  },
  methods: {
    ...mapActions({
      fetchFirmEmployeInfo: 'firmInventory/action_get_firmEmployementInfo',
    }),
    ...mapMutations({
      setFirmEplInfo: 'firmInventory/SET_FIRMEMPLOYEMENTINFO',
    }),
    getIsthidamByFirmId() {
      this.fetchFirmEmployeInfo(this.firmId).then(() => {
        this.selectkstaj = `ks0${this.firmEmployeInfo.acceptTrainee}`
        this.chkeml = this.firmEmployeInfo.acceptVocationalTrainee
        this.chkmyo = this.firmEmployeInfo.acceptAssociateTrainee
        this.chkunv = this.firmEmployeInfo.acceptBachelorTrainee
        this.selectanket2 = `c0${this.firmEmployeInfo.acceptLongTermTrainee}`
        this.selectanket3 = `c0${this.firmEmployeInfo.iskurApps}`
        this.selectanket4 = `c0${this.firmEmployeInfo.joinLessons}`
      })
    },
  },
}
</script>

<style lang='scss'>
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
