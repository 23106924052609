<template>
  <div>
    <b-row class=""> <!--  Kullanılacak kanallar  -->
      <b-col>
        <p class="fs-3">
          Aradığınız personeli genellikle hangi kanallardan temin edersiniz.
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-form-group>
        <b-form-checkbox-group
          id="checkboxPBK"
          v-model="selectedPBK"
          :options="optionsPBK"
          name="PBK"
          class="demo-inline-spacing mb-1"
        />
        <b-form-input
          id="pt8"
          v-model="firmEmployementPolicy.firmStaffingMethodsDescription"
          name="DigerPBK"
          :disabled="!selectedPBK.includes('pt7')"
          placeholder="Açıklayınız."
          size="sm"
        />
      </b-form-group>
    </b-row>
    <b-row> <!--  Personel ihtiyaç iletişim  -->
      <div class="mt-1">
        <b-form-group>
          <p class="fs-3">
            Personel ihtiyacı olduğunda yazılım üzerinden mi, çağrı merkezi üzerinden mi, iletişim kurmak istersiniz?
          </p>
          <b-form-radio-group
            v-model="selectedi01"
            :options="optionsi01"
          />
        </b-form-group>
      </div>
    </b-row>

    <b-row> <!-- Belediye personel temin -->
      <div class="">
        <b-form-group>
          <p class="fs-3">
            Belediye personel temin konusunda imkan sağladığı takdirde istifade etmek ister misiniz?
          </p>
          <b-form-radio-group
            v-model="selectedbpt01"
            :options="optionsbpt01"
          />
        </b-form-group>
      </div>
    </b-row>

    <b-row> <!-- Belediye  pozisyonları -->
      <b-col>
        <p class="fs-3">
          Hangi pozisyonlarda istifade etmek ister misiniz? (Bu soru 4. Şıkkı işaretleyenlere sorulacaktır.)
        </p>
        <b-row class="mb-1">
          <b-col
            cols="1"
            md="1"
          >
            1.
          </b-col>
          <b-col
            cols="4"
            md="5"
          >
            <b-form-input
              id="poz1"
              v-model="firmEmployementPolicy.firmStaffingPosition1"
              :disabled="selectedbpt01!='bpt014'"
              placeholder="Açıklayınız."
              size="sm"
            />
          </b-col>
          <b-col
            cols="2"
            md="1"
          >
            Faktör 1
          </b-col>
          <b-col
            cols="5"
            md="5"
          >
            <v-select
              id="fak1"
              v-model="selectedfaktor1"
              label="staffPositionName"
              :options="faktoroption"
              class="select-size-sm"
            />
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="1"
            md="1"
          >
            2.
          </b-col>
          <b-col
            cols="4"
            md="5"
          >
            <b-form-input
              id="poz2"
              v-model="firmEmployementPolicy.firmStaffingPosition2"
              :disabled="selectedbpt01!='bpt014'"
              placeholder="Açıklayınız."
              size="sm"
            />
          </b-col>
          <b-col
            cols="2"
            md="1"
          >
            Faktör 2
          </b-col>
          <b-col
            cols="5"
            md="5"
          >
            <v-select
              id="fak2"
              v-model="selectedfaktor2"
              label="staffPositionName"
              :options="faktoroption"
              class="select-size-sm"
            />
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="1"
            md="1"
          >
            3.
          </b-col>
          <b-col
            cols="4"
            md="5"
          >
            <b-form-input
              id="poz3"
              v-model="firmEmployementPolicy.firmStaffingPosition3"
              :disabled="selectedbpt01!='bpt014'"
              placeholder="Açıklayınız."
              size="sm"
            />
          </b-col>
          <b-col
            cols="2"
            md="1"
          >
            Faktör 3
          </b-col>
          <b-col
            cols="5"
            md="5"
          >
            <v-select
              id="fak3"
              v-model="selectedfaktor3"
              label="staffPositionName"
              :options="faktoroption"
              class="select-size-sm"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="">
      <p class="fs-3">
        Personel sayınızda yıllık ortalama ne kadar artış olmaktadır.?
      </p>
    </b-row>
    <b-row class="mb-2 pb-1">  <!-- Yıllık  personel artışı -->
      <b-col cols="6">
        <b-form-group>
          <label for="Psay1">Sayı</label>
          <b-form-input
            id="Psay1"
            v-model="firmEmployementPolicy.firmYearlyStaffIncrementNumber"
            placeholder="Açıklayınız."
            type="number"
            min="0"
            step="1"
            size="sm"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group>
          <label for="Psay2">Oran %</label>
          <b-form-input
            id="Psay2"
            v-model="firmEmployementPolicy.firmYearlyStaffIncrementPercent"
            type="number"
            placeholder="Açıklayınız."
            size="sm"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row> <!-- Gelecekte ihtiyaç alanlar -->
      <b-col>
        <p class="fs-3">
          Gelecekte hangi alanlarda personele ihtiyaç duyacağınızı tahmin etmektesiniz?
        </p>
        <b-row class="mb-1">
          <b-col
            cols="1"
            md="1"
          >
            1.
          </b-col>
          <b-col
            cols="4"
            md="5"
          >
            <b-form-input
              id="pgel1"
              v-model="firmEmployementPolicy.firmFutureStaffingPosition1"
              placeholder="Açıklayınız."
              size="sm"
            />
          </b-col>
          <b-col
            cols="2"
            md="1"
          >
            Faktör 1
          </b-col>
          <b-col
            cols="5"
            md="5"
          >
            <v-select
              id="fakgel1"
              v-model="selectedfakgel1"
              label="staffPositionName"
              :options="faktorgel"
              class="select-size-sm"
            />
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="1"
            md="1"
          >
            2.
          </b-col>
          <b-col
            cols="4"
            md="5"
          >
            <b-form-input
              id="pgel2"
              v-model="firmEmployementPolicy.firmFutureStaffingPosition2"
              placeholder="Açıklayınız."
              size="sm"
            />
          </b-col>
          <b-col
            cols="2"
            md="1"
          >
            Faktör 2
          </b-col>
          <b-col
            cols="5"
            md="5"
          >
            <v-select
              id="fakgel2"
              v-model="selectedfakgel2"
              label="staffPositionName"
              :options="faktorgel"
              class="select-size-sm"
            />
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="1"
            md="1"
          >
            3.
          </b-col>
          <b-col
            cols="4"
            md="5"
          >
            <b-form-input
              id="pgel3"
              v-model="firmEmployementPolicy.firmFutureStaffingPosition3"
              placeholder="Açıklayınız."
              size="sm"
            />
          </b-col>
          <b-col
            cols="2"
            md="1"
          >
            Faktör 3
          </b-col>
          <b-col
            cols="5"
            md="5"
          >
            <v-select
              id="fakgel3"
              v-model="selectedfakgel3"
              label="staffPositionName"
              :options="faktorgel"
              class="select-size-sm"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class=""> <!-- Personel ihtiyaç eğitim -->
      <b-col>
        <p class="fs-3">
          Şirketiniz personelin işlerini daha iyi yapabilmeleri için ihtiyaç duyduğunuz eğitimler nelerdir?
        </p>
        <b-row class="mb-1">
          <b-col
            cols="1"
            md="1"
          >
            1.
          </b-col>
          <b-col
            cols="4"
            md="5"
          >
            <b-form-input
              id="pegt1"
              v-model="firmEmployementPolicy.firmStaffEducation1"
              placeholder="Açıklayınız."
              size="sm"
            />
          </b-col>
          <b-col
            cols="2"
            md="1"
          >
            Faktör 1
          </b-col>
          <b-col
            cols="5"
            md="5"
          >
            <v-select
              id="fakegt1"
              v-model="selectedfakegt1"
              label="staffEducationName"
              :options="faktoregt"
              class="select-size-sm"
            />
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="1"
            md="1"
          >
            2.
          </b-col>
          <b-col
            cols="4"
            md="5"
          >
            <b-form-input
              id="pegt2"
              v-model="firmEmployementPolicy.firmStaffEducation2"
              placeholder="Açıklayınız."
              size="sm"
            />
          </b-col>
          <b-col
            cols="2"
            md="1"
          >
            Faktör 2
          </b-col>
          <b-col
            cols="5"
            md="5"
          >
            <v-select
              id="fakegt2"
              v-model="selectedfakegt2"
              label="staffEducationName"
              :options="faktoregt"
              class="select-size-sm"
            />
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="1"
            md="1"
          >
            3.
          </b-col>
          <b-col
            cols="4"
            md="5"
          >
            <b-form-input
              id="pegt3"
              v-model="firmEmployementPolicy.firmStaffEducation3"
              placeholder="Açıklayınız."
              size="sm"
            />
          </b-col>
          <b-col
            cols="2"
            md="1"
          >
            Faktör 3
          </b-col>
          <b-col
            cols="5"
            md="5"
          >
            <v-select
              id="fakegt3"
              v-model="selectedfakegt3"
              label="staffEducationName"
              :options="faktoregt"
              class="select-size-sm"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

  </div>
</template>
<script>

// eslint-disable-next-line object-curly-newline
import { BCol, BRow, BFormCheckboxGroup, BFormInput, BFormRadioGroup, BFormGroup } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import * as apiPPoz from '@/api/tanimlar/personelpozisyon'
import * as apiEgt from '@/api/tanimlar/StaffEducation'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    vSelect,
    BCol,
    BRow,
    BFormGroup,
    BFormRadioGroup,
    BFormInput,
    BFormCheckboxGroup,
  },
  directives: {
    Ripple,
  },
  props: {
    firmId: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  data() {
    return {
      selchk: '',
      tm11: '',
      tm12: '',
      tm21: '',
      tm22: '',
      tm31: '',
      tm32: '',
      tm41: '',
      tm42: '',
      selectedi01: 'i011',
      selectedfaktor1: null,
      defaultSelectedFaktor1: { staffPositionId: null, staffPositionName: '' },
      selectedfaktor2: null,
      defaultSelectedFaktor2: { staffPositionId: null, staffPositionName: '' },
      selectedfaktor3: null,
      defaultSelectedFaktor3: { staffPositionId: null, staffPositionName: '' },
      faktoroption: [],

      selectedfakgel1: null,
      defaultSelectedFakGel1: { staffPositionId: null, staffPositionName: '' },
      selectedfakgel2: null,
      defaultSelectedFakGel2: { staffPositionId: null, staffPositionName: '' },
      selectedfakgel3: null,
      defaultSelectedFakGel3: { staffPositionId: null, staffPositionName: '' },
      faktorgel: [],

      selectedfakegt1: null,
      defaultSelectedFakEgt1: { staffEducationId: null, staffEducationName: '' },
      selectedfakegt2: null,
      defaultSelectedFakEgt2: { staffEducationId: null, staffEducationName: '' },
      selectedfakegt3: null,
      defaultSelectedFakEgt3: { staffEducationId: null, staffEducationName: '' },
      faktoregt: [],

      selectedPBK: [],
      optionsPBK: [
        { text: 'Kendi web siteme ilan veririm.', value: 'pt1' },
        { text: 'Gazete ilanı veririm.', value: 'pt2' },
        { text: 'Kariyer sitelerine ilan veririm.', value: 'pt3' },
        { text: 'Eş dost ve tanıdıklara haber veririm.', value: 'pt4' },
        { text: 'Üniversitelerle görüşürüm.', value: 'pt5' },
        { text: 'İŞKURa başvururum.', value: 'pt6' },
        { text: 'Diğer seçenekleri açıklayınız.', value: 'pt7' },
      ],

      optionsi01: [
        { html: '<p style="font-size:12px;">Web sitesi üzerinden.</p>', value: 'i011', disabled: false },
        { html: '<p style="font-size:12px;">Çağrı merkezinden</p>', value: 'i012', disabled: false },
        { html: '<p style="font-size:12px;">Farketmez.</p>', value: 'i013', disabled: false },
      ],
      selectedbpt01: 'bpt011',
      optionsbpt01: [
        { html: '<p style="font-size:12px;">Kesinlikle istmem.</p>', value: 'bpt011', disabled: false },
        { html: '<p style="font-size:12px;">Nadir de olsa kullanabilirim.</p>', value: 'bpt012', disabled: false },
        { html: '<p style="font-size:12px;">Hizmet kalitesine göre karar veririm.</p>', value: 'bpt013', disabled: false },
        { html: '<p style="font-size:12px;">Bazı pozisyonlarda istifade ederim.</p>', value: 'bpt014', disabled: false },
        { html: '<p style="font-size:12px;">Her zaman belediyeyi tercih ederim.</p>', value: 'bpt015', disabled: false },
      ],
    }
  },
  computed: {
    ...mapGetters({
      firmInfo: 'firmInventory/GET_FIRMINFO',
      firmEmployementPolicy: 'firmInventory/GET_EMPLOYEMENTPOLICY',
    }),
  },
  watch: {
    selectedi01() {
      this.firmEmployementPolicy.firmContactType = parseInt(this.selectedi01.substring(this.selectedi01.length - 1, this.selectedi01.length), 10)
    },
    selectedbpt01() {
      this.firmEmployementPolicy.firmStaffingOption = parseInt(this.selectedbpt01.substring(this.selectedbpt01.length - 1, this.selectedbpt01.length), 10)
    },
    selectedfaktor1() {
      this.firmEmployementPolicy.factorFirmStaffingPosition1Id = this.selectedfaktor1.staffPositionId
    },
    selectedfaktor2() {
      this.firmEmployementPolicy.factorFirmStaffingPosition2Id = this.selectedfaktor2.staffPositionId
    },
    selectedfaktor3() {
      this.firmEmployementPolicy.factorFirmStaffingPosition3Id = this.selectedfaktor3.staffPositionId
    },
    selectedfakgel1() {
      this.firmEmployementPolicy.factorFirmFutureStaffingPosition1Id = this.selectedfakgel1.staffPositionId
    },
    selectedfakgel2() {
      this.firmEmployementPolicy.factorFirmFutureStaffingPosition2Id = this.selectedfakgel2.staffPositionId
    },
    selectedfakgel3() {
      this.firmEmployementPolicy.factorFirmFutureStaffingPosition3Id = this.selectedfakgel3.staffPositionId
    },
    selectedfakegt1() {
      this.firmEmployementPolicy.factorFirmStaffEducation1Id = this.selectedfakegt1.staffEducationId
    },
    selectedfakegt2() {
      this.firmEmployementPolicy.factorFirmStaffEducation2Id = this.selectedfakegt2.staffEducationId
    },
    selectedfakegt3() {
      this.firmEmployementPolicy.factorFirmStaffEducation3Id = this.selectedfakegt3.staffEducationId
    },
    selectedPBK() {
      this.firmEmployementPolicy.firmStaffingMethods = this.selectedPBK
    },
    firmId() {
      this.getFirmEmployementPolicy()
    },
  },
  mounted() {
    this.getPersonelPL()
    this.getPersonelAlan()
    this.getPersonelEgt()
    this.getFirmEmployementPolicy()
  },
  methods: {
    ...mapActions({
      fetchFirmEmployePolicy: 'firmInventory/action_get_firmEmployementPolicy',
    }),
    ...mapMutations({
      setFirmEmployePolicy: 'firmInventory/SET_EMPLOYEMENTPOLICY',
    }),
    getPersonelPL() {
      apiPPoz.GetPersonelPozisyonList().then(res => {
        this.faktoroption = res.data.list
      })
    },
    getPersonelAlan() {
      apiPPoz.GetPersonelPozisyonList().then(res => {
        this.faktorgel = res.data.list
      })
    },
    getPersonelEgt() {
      apiEgt.getStaffEducations().then(res => {
        this.faktoregt = res.data.list
      })
    },
    getFirmEmployementPolicy() {
      this.fetchFirmEmployePolicy(this.firmId).then(() => {
        this.selectedPBK = this.firmEmployementPolicy?.firmStaffingMethods
        this.selectedi01 = `i01${this.firmEmployementPolicy?.firmContactType}`
        this.selectedbpt01 = `bpt01${this.firmEmployementPolicy?.firmStaffingOption}`

        this.selectedfaktor1 = this.firmEmployementPolicy?.factorFirmStaffingPosition1 ?? { ...this.defaultSelectedFaktor1 }
        this.selectedfaktor2 = this.firmEmployementPolicy?.factorFirmStaffingPosition2 ?? { ...this.defaultSelectedFaktor2 }
        this.selectedfaktor3 = this.firmEmployementPolicy?.factorFirmStaffingPosition3 ?? { ...this.defaultSelectedFaktor3 }

        this.selectedfakgel1 = this.firmEmployementPolicy?.factorFirmFutureStaffingPosition1 ?? { ...this.defaultSelectedFakGel1 }
        this.selectedfakgel2 = this.firmEmployementPolicy?.factorFirmFutureStaffingPosition2 ?? { ...this.defaultSelectedFakGel2 }
        this.selectedfakgel3 = this.firmEmployementPolicy?.factorFirmFutureStaffingPosition3 ?? { ...this.defaultSelectedFakGel3 }

        this.selectedfakegt1 = this.firmEmployementPolicy?.factorFirmStaffEducation1 ?? { ...this.defaultSelectedFakEgt1 }
        this.selectedfakegt2 = this.firmEmployementPolicy?.factorFirmStaffEducation2 ?? { ...this.defaultSelectedFakEgt2 }
        this.selectedfakegt3 = this.firmEmployementPolicy?.factorFirmStaffEducation3 ?? { ...this.defaultSelectedFakEgt3 }
        if (this.firmId < 1) this.resetManual()
      })
    },
    resetManual() {
      this.firmEmployementPolicy.firmStaffingMethods = ''
    },
  },
}
</script>

<style lang='scss'>
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
