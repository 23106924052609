<template>
  <div>
    <div class="">
      <b-form-group>
        <label>Bir istihdam kriteri olarak tecrübe yaklaşımı.</label>
        <b-form-radio-group
          v-model="selectedq01"
          :options="optionsq01"
        />
      </b-form-group>
    </div>
    <div class="">
      <b-form-group>
        <label>Bir istihdam kriteri olarak Eğitim/Tahsil yaklaşımı.</label>
        <b-form-radio-group
          v-model="selectedq02"
          :options="optionsq02"
        />
      </b-form-group>
    </div>
    <div class="">
      <b-form-group>
        <label>Bir istihdam kriteri olarak Referans yaklaşımı.</label>
        <b-form-radio-group
          v-model="selectedq03"
          :options="optionsq03"
        />
      </b-form-group>
    </div>
    <div class="">
      <b-form-group>
        <label>Bir istihdam kriteri olarak Sertifikalı Personel yaklaşımı.</label>
        <b-form-radio-group
          v-model="selectedq04"
          :options="optionsq04"
        />
      </b-form-group>
    </div>
    <div class="">
      <b-form-group>
        <label>İstihdam edeceğim personelde Bilgi ve Beceri Tahsil Seviyesinden daha önemlidir.</label>
        <b-form-radio-group
          v-model="selectedq05"
          :options="optionsq05"
        />
      </b-form-group>
    </div>
    <div class="">
      <b-form-group>
        <label>İhtiyaç duyduğumuz nitelikte personel bulabilirsem daha fazla personel istihdam ederim.</label>
        <b-form-radio-group
          v-model="selectedq06"
          :options="optionsq06"
        />
      </b-form-group>
    </div>
    <div class="">
      <b-form-group>
        <label>Personel bulmanın zorluğu nedeniyle İşi Büyütmekten/Yatırım yapmaktan kaçınıyorum.</label>
        <b-form-radio-group
          v-model="selectedq07"
          :options="optionsq07"
        />
      </b-form-group>
    </div>
    <div class="">
      <b-form-group>
        <label>Personeli stajer olarak başlatıp sonrasında istihdam etmeyi tercih ederim.</label>
        <b-form-radio-group
          v-model="selectedq08"
          :options="optionsq08"
        />
      </b-form-group>
    </div>
    <div class="">
      <b-form-group>
        <label>Aynı sektörde faaliyet gösteren diğer firmalar(sizinle aynı seviyede) ile karşılaştırdığınızda Ücret Seviyenizi değerlendiriniz.</label>
        <b-row>
          <b-col>
            <b-form-radio-group
              v-model="selectedq09"
              :options="optionsq09"
            />
          </b-col>
          <b-col
            cols="2"
            class="align-bottom"
          >
            <b-form-input
              id="q096"
              v-model="FirmEmployementPolicy.q9Description"
              :disabled="selectedq09!='q096'"
              placeholder="Açıklayınız."
              size="sm"
            />
          </b-col>
        </b-row>
      </b-form-group>
    </div>
    <div class="">
      <b-form-group>
        <label>Aynı sektörde faaliyet gösteren diğer firmalar(sizinle aynı seviyede) ile karşılaştırdığınızda Büyüme Eğilimimiz daha yüksektir.</label>
        <b-form-radio-group
          v-model="selectedq10"
          :options="optionsq10"
        />
      </b-form-group>
    </div>
    <div class="">
      <label>İstihdam edeceğiniz personelde en önemli kriter nedir?</label>
      <b-form-textarea
        id="pkriter"
        v-model="FirmEmployementPolicy.q11Text"
        size="sm"
        placeholder="Personel kriteri"
      />
    </div>
  </div>
</template>

<script>
import {
  BFormRadioGroup, BFormTextarea, BFormInput, BRow, BCol, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    BFormRadioGroup,
    BFormTextarea,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    firmId: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  data() {
    return {
      selectedq01: 'q011',
      optionsq01: [
        { html: '<p style="font-size:12px;">Yetiştirmek üzere personel alımı</p>', value: 'q011', disabled: false },
        { html: '<p style="font-size:12px;">Az da olsa bir yerde çlışmış personel tercih ederim</p>', value: 'q012', disabled: false },
        { html: '<p style="font-size:12px;">Pozisyona ve ihtiyaca göre karar veririm</p>', value: 'q013', disabled: false },
        { html: '<p style="font-size:12px;">Tecrübeli olan personel tercih ederim</p>', value: 'q014', disabled: false },
        { html: '<p style="font-size:12px;">Kesinlikle tecrübeli personel alırım</p>', value: 'q015', disabled: false },
      ],
      selectedq02: 'q021',
      optionsq02: [
        { html: '<p style="font-size:12px;">Tahsil benim için önemli değildir.</p>', value: 'q021', disabled: false },
        { html: '<p style="font-size:12px;">En azından lise mezunu olması gerekir.</p>', value: 'q022', disabled: false },
        { html: '<p style="font-size:12px;">Pozisyona ve ihtiyaca göre karar veririm.</p>', value: 'q023', disabled: false },
        { html: '<p style="font-size:12px;">Tahsili olan personel tercih ederim.</p>', value: 'q024', disabled: false },
        { html: '<p style="font-size:12px;">Kesinlikle tahsilli personel alırım.</p>', value: 'q025', disabled: false },
      ],
      selectedq03: 'q031',
      optionsq03: [
        { html: '<p style="font-size:12px;">Personelin referansına bakmam.</p>', value: 'q031', disabled: false },
        { html: '<p style="font-size:12px;">En azından referans gösterebilecek biri olmalı.</p>', value: 'q032', disabled: false },
        { html: '<p style="font-size:12px;">Pozisyona ve ihtiyaca göre karar veririm.</p>', value: 'q033', disabled: false },
        { html: '<p style="font-size:12px;">Referansla gönderilen personel tercih ederim.</p>', value: 'q034', disabled: false },
        { html: '<p style="font-size:12px;">Kesinlikle referansla gönderilen personel tercih ederim.</p>', value: 'q035', disabled: false },
      ],
      selectedq04: 'q041',
      optionsq04: [
        { html: '<p style="font-size:12px;">Sertifika sahibi olması önemli değildir.</p>', value: 'q041', disabled: false },
        { html: '<p style="font-size:12px;">Sertifikası olsa iyi olur.</p>', value: 'q042', disabled: false },
        { html: '<p style="font-size:12px;">Pozisyona ve ihtiyaca göre karar veririm.</p>', value: 'q043', disabled: false },
        { html: '<p style="font-size:12px;">Sertifikası olanı tercih ederim.</p>', value: 'q044', disabled: false },
        { html: '<p style="font-size:12px;">Kesinlikle sertifikası olmalıdır.</p>', value: 'q045', disabled: false },
      ],
      selectedq05: 'q051',
      optionsq05: [
        { html: '<p style="font-size:12px;">Kesinlikle katılmıyorum.</p>', value: 'q051', disabled: false },
        { html: '<p style="font-size:12px;">Katılmıyorum.</p>', value: 'q052', disabled: false },
        { html: '<p style="font-size:12px;">Kararsızım.</p>', value: 'q053', disabled: false },
        { html: '<p style="font-size:12px;">Katılıyorum.</p>', value: 'q054', disabled: false },
        { html: '<p style="font-size:12px;">Kesinlikle katılıyorum.</p>', value: 'q055', disabled: false },
      ],
      selectedq06: 'q061',
      optionsq06: [
        { html: '<p style="font-size:12px;">Kesinlikle katılmıyorum.</p>', value: 'q061', disabled: false },
        { html: '<p style="font-size:12px;">Katılmıyorum.</p>', value: 'q062', disabled: false },
        { html: '<p style="font-size:12px;">Kararsızım.</p>', value: 'q063', disabled: false },
        { html: '<p style="font-size:12px;">Katılıyorum.</p>', value: 'q064', disabled: false },
        { html: '<p style="font-size:12px;">Kesinlikle katılıyorum.</p>', value: 'q065', disabled: false },
      ],
      selectedq07: 'q071',
      optionsq07: [
        { html: '<p style="font-size:12px;">Kesinlikle katılmıyorum.</p>', value: 'q071', disabled: false },
        { html: '<p style="font-size:12px;">Katılmıyorum.</p>', value: 'q072', disabled: false },
        { html: '<p style="font-size:12px;">Kararsızım.</p>', value: 'q073', disabled: false },
        { html: '<p style="font-size:12px;">Katılıyorum.</p>', value: 'q074', disabled: false },
        { html: '<p style="font-size:12px;">Kesinlikle katılıyorum.</p>', value: 'q075', disabled: false },
      ],
      selectedq08: 'q081',
      optionsq08: [
        { html: '<p style="font-size:12px;">Kesinlikle katılmıyorum.</p>', value: 'q081', disabled: false },
        { html: '<p style="font-size:12px;">Katılmıyorum.</p>', value: 'q082', disabled: false },
        { html: '<p style="font-size:12px;">Kararsızım.</p>', value: 'q083', disabled: false },
        { html: '<p style="font-size:12px;">Katılıyorum.</p>', value: 'q084', disabled: false },
        { html: '<p style="font-size:12px;">Kesinlikle katılıyorum.</p>', value: 'q085', disabled: false },
      ],
      selectedq09: 'q091',
      optionsq09: [
        { html: '<p style="font-size:12px;">Kadroya göre ücret öderim.</p>', value: 'q091', disabled: false },
        { html: '<p style="font-size:12px;">Eşit işe eşit ücret öderim.</p>', value: 'q092', disabled: false },
        { html: '<p style="font-size:12px;">Personel niteliğine göre ücret öderim.</p>', value: 'q093', disabled: false },
        { html: '<p style="font-size:12px;">Performansa göre ücret öderim.</p>', value: 'q094', disabled: false },
        { html: '<p style="font-size:12px;">Belirgin bir poitikam yok.</p>', value: 'q095', disabled: false },
        { html: '<p style="font-size:12px;">Diğer.</p>', value: 'q096', disabled: false },
      ],
      selectedq10: 'q101',
      optionsq10: [
        { html: '<p style="font-size:12px;">Kesinlikle katılmıyorum.</p>', value: 'q101', disabled: false },
        { html: '<p style="font-size:12px;">Katılmıyorum.</p>', value: 'q102', disabled: false },
        { html: '<p style="font-size:12px;">Kararsızım.</p>', value: 'q103', disabled: false },
        { html: '<p style="font-size:12px;">Katılıyorum.</p>', value: 'q104', disabled: false },
        { html: '<p style="font-size:12px;">Kesinlikle katılıyorum.</p>', value: 'q105', disabled: false },
      ],
    }
  },
  computed: {
    ...mapGetters({
      firmInfo: 'firmInventory/GET_FIRMINFO',
      FirmEmployementPolicy: 'firmInventory/GET_EMPLOYEMENTPOLICY',
    }),
  },
  watch: {
    selectedq01() {
      this.FirmEmployementPolicy.q1Value = this.selectedq01.substring(this.selectedq01.length - 1, this.selectedq01.length)
    },
    selectedq02() {
      this.FirmEmployementPolicy.q2Value = this.selectedq02.substring(this.selectedq02.length - 1, this.selectedq02.length)
    },
    selectedq03() {
      this.FirmEmployementPolicy.q3Value = this.selectedq03.substring(this.selectedq03.length - 1, this.selectedq03.length)
    },
    selectedq04() {
      this.FirmEmployementPolicy.q4Value = this.selectedq04.substring(this.selectedq04.length - 1, this.selectedq04.length)
    },
    selectedq05() {
      this.FirmEmployementPolicy.q5Value = this.selectedq05.substring(this.selectedq05.length - 1, this.selectedq05.length)
    },
    selectedq06() {
      this.FirmEmployementPolicy.q6Value = this.selectedq06.substring(this.selectedq06.length - 1, this.selectedq06.length)
    },
    selectedq07() {
      this.FirmEmployementPolicy.q7Value = this.selectedq07.substring(this.selectedq07.length - 1, this.selectedq07.length)
    },
    selectedq08() {
      this.FirmEmployementPolicy.q8Value = this.selectedq08.substring(this.selectedq08.length - 1, this.selectedq08.length)
    },
    selectedq09() {
      this.FirmEmployementPolicy.q9Value = this.selectedq09.substring(this.selectedq09.length - 1, this.selectedq09.length)
    },
    selectedq10() {
      this.FirmEmployementPolicy.q10Value = this.selectedq10.substring(this.selectedq10.length - 1, this.selectedq10.length)
    },
    firmId() {
      this.getFirmData()
    },
  },
  mounted() {
    // this.getFpolicy()
    this.getFirmData()
    // this.getFirmEmployementPolicy()
  },
  methods: {
    ...mapMutations({
      setFirmInfo: 'firmInventory/SET_FIRMINFO',
      setFirmEmployementPolicy: 'firmInventory/SET_EMPLOYEMENTPOLICY',
    }),
    ...mapActions({
      fetchFirmInfo: 'firmInventory/action_get_firminfo',
      fetchFirmEmployementPolicy: 'firmInventory/action_get_firmEmployementPolicy',
      resetFirmInventory: 'firmInventory/action_reset_firmInventory',
    }),
    getFirmData() {
      this.fetchFirmInfo(this.firmId).then(() => {
        this.getFirmEmployementPolicy()
      })
    },
    getFirmEmployementPolicy() {
      this.fetchFirmEmployementPolicy(this.firmId).then(() => {
        this.selectedq01 = `q01${this.FirmEmployementPolicy.q1Value}`
        this.selectedq02 = `q02${this.FirmEmployementPolicy.q2Value}`
        this.selectedq03 = `q03${this.FirmEmployementPolicy.q3Value}`
        this.selectedq04 = `q04${this.FirmEmployementPolicy.q4Value}`
        this.selectedq05 = `q05${this.FirmEmployementPolicy.q5Value}`
        this.selectedq06 = `q06${this.FirmEmployementPolicy.q6Value}`
        this.selectedq07 = `q07${this.FirmEmployementPolicy.q7Value}`
        this.selectedq08 = `q08${this.FirmEmployementPolicy.q8Value}`
        this.selectedq09 = `q09${this.FirmEmployementPolicy.q9Value}`
        this.selectedq10 = `q10${this.FirmEmployementPolicy.q10Value}`
        if (this.firmId < 1) this.resetManual()
      })
    },
    resetManual() {
      this.FirmEmployementPolicy.q1Value = 0
      this.FirmEmployementPolicy.q2Value = 0
      this.FirmEmployementPolicy.q3Value = 0
      this.FirmEmployementPolicy.q4Value = 0
      this.FirmEmployementPolicy.q5Value = 0
      this.FirmEmployementPolicy.q6Value = 0
      this.FirmEmployementPolicy.q7Value = 0
      this.FirmEmployementPolicy.q8Value = 0
      this.FirmEmployementPolicy.q9Value = 0
      this.FirmEmployementPolicy.q10Value = 0
    },
  },
}
</script>
