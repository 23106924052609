<template>
  <div>
    <b-row class="mb-1 mt-1">
      <b-col cols="2">
        <b>Personel Sınıfı</b>
      </b-col>
      <b-col cols="1">
        <b>Sayısı</b>
      </b-col>
      <b-col cols="1">
        <b>Devir %</b>
      </b-col>
      <b-col cols="2">
        <b>Bulma Zorluğu</b>
      </b-col>
      <b-col cols="2">
        <b>Temin Yöntemi</b>
      </b-col>
      <b-col cols="2">
        <b>Anlaşamama Nedenleri</b>
      </b-col>
      <b-col cols="2">
        <b>Nitelik Beklentisi</b>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        scope="row"
        class="text-primary"
      >
        İmalatta çalışan (İşçi):
      </b-col>
      <b-col cols="1"> <!-- İşçi Satırı -->
        <b-form-group>
          <b-input-group class="input-group-merge">
            <b-form-input
              id="isci1-1"
              v-model="firmEmployeInfo.workerStaffCount"
              placeholder="İşçi Sayısı"
              type="number"
              min="0"
              step="1"
              size="sm"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="1">
        <b-form-group>
          <b-input-group class="input-group-merge">
            <b-form-input
              id="isci1-2"
              v-model="firmEmployeInfo.workerStaffCycleRate"
              placeholder="Devir %"
              type="number"
              min="0"
              step="1"
              size="sm"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group>
          <v-select
            id="isci1-3"
            v-model="selWBZ"
            label="staffFindingDifficultyDescription"
            :options="optionsbulzor"
            class="select-size-sm"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <v-select
          id="isci1-4"
          v-model="selWSM"
          label="staffingMethodDescription"
          :options="optionsPTY"
          class="select-size-sm"
        />
      </b-col>
      <b-col>
        <v-select
          id="isci1-5"
          v-model="selWDR"
          label="disagreementReasonDescription"
          :options="optionsPAN"
          class="select-size-sm"
        />
      </b-col>
      <b-col>
        <b-form-group>
          <b-input-group class="input-group-merge">
            <b-form-input
              id="isci1-6"
              v-model="firmEmployeInfo.workerQualificationExpectation"
              placeholder="Nitelik Beklentisi"
              type="text"
              size="sm"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        scope="row"
        style="color:#0288d1;"
      >
        Fonksiyonel Uzman:
      </b-col>
      <b-col cols="1">
        <b-form-group>
          <b-input-group class="input-group-merge">
            <b-form-input
              id="isci2-1"
              v-model="firmEmployeInfo.functionalSpecialistCount"
              placeholder="Uzman Sayısı"
              type="number"
              min="0"
              step="1"
              size="sm"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="1">
        <b-form-group>
          <b-input-group class="input-group-merge">
            <b-form-input
              id="isci2-2"
              v-model="firmEmployeInfo.functionalSpecialistCycleRate"
              placeholder="Devir %"
              type="number"
              min="0"
              step="1"
              size="sm"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group>
          <v-select
            id="isci2-3"
            v-model="selFBZ"
            label="staffFindingDifficultyDescription"
            :options="optionsbulzor"
            class="select-size-sm"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <v-select
          id="isci2-4"
          v-model="selFSM"
          label="staffingMethodDescription"
          :options="optionsPTY"
          class="select-size-sm"
        />
      </b-col>
      <b-col>
        <v-select
          id="isci2-5"
          v-model="selFDR"
          label="disagreementReasonDescription"
          :options="optionsPAN"
          class="select-size-sm"
        />
      </b-col>
      <b-col>
        <b-form-group>
          <b-input-group class="input-group-merge">
            <b-form-input
              id="isci2-6"
              v-model="firmEmployeInfo.functionalSpecialistQualificationExpectation"
              placeholder="Nitelik Beklentisi"
              type="text"
              size="sm"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        scope="row"
        style="color:#0288d1;"
      >
        Mesleki Uzman:
      </b-col>
      <b-col cols="1">
        <b-form-group>
          <b-input-group class="input-group-merge">
            <b-form-input
              id="isci3-1"
              v-model="firmEmployeInfo.vocationalSpecialistCount"
              placeholder="Uzman Sayısı"
              type="number"
              min="0"
              step="1"
              size="sm"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="1">
        <b-form-group>
          <b-input-group class="input-group-merge">
            <b-form-input
              id="isci3-2"
              v-model="firmEmployeInfo.vocationalSpecialistCycleRate"
              placeholder="Devir %"
              type="number"
              min="0"
              step="1"
              size="sm"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group>
          <v-select
            id="isci3-3"
            v-model="selVBZ"
            label="staffFindingDifficultyDescription"
            :options="optionsbulzor"
            class="select-size-sm"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <v-select
          id="isci3-4"
          v-model="selVSM"
          label="staffingMethodDescription"
          :options="optionsPTY"
          class="select-size-sm"
        />
      </b-col>
      <b-col>
        <v-select
          id="isci3-5"
          v-model="selVDR"
          label="disagreementReasonDescription"
          :options="optionsPAN"
          class="select-size-sm"
        />
      </b-col>
      <b-col>
        <b-form-group>
          <b-input-group class="input-group-merge">
            <b-form-input
              id="isci3-6"
              v-model="firmEmployeInfo.vocationalSpecialistQualificationExpectation"
              placeholder="Nitelik Beklentisi"
              type="text"
              size="sm"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        scope="row"
        style="color:#0288d1;"
      >
        Destek Personeli:
      </b-col>
      <b-col cols="1">
        <b-form-group>
          <b-input-group class="input-group-merge">
            <b-form-input
              id="isci4-1"
              v-model="firmEmployeInfo.supportStaffCount"
              placeholder="Personel Sayısı"
              type="number"
              min="0"
              step="1"
              size="sm"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="1">
        <b-form-group>
          <b-input-group class="input-group-merge">
            <b-form-input
              id="isci4-2"
              v-model="firmEmployeInfo.supportStaffCycleRate"
              placeholder="Devir %"
              type="number"
              min="0"
              step="1"
              size="sm"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group>
          <v-select
            id="isci4-3"
            v-model="selSBZ"
            label="staffFindingDifficultyDescription"
            :options="optionsbulzor"
            class="select-size-sm"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <v-select
          id="isci4-4"
          v-model="selSSM"
          label="staffingMethodDescription"
          :options="optionsPTY"
          class="select-size-sm"
        />
      </b-col>
      <b-col>
        <v-select
          id="isci4-5"
          v-model="selSDR"
          label="disagreementReasonDescription"
          :options="optionsPAN"
          class="select-size-sm"
        />
      </b-col>
      <b-col>
        <b-form-group>
          <b-input-group class="input-group-merge">
            <b-form-input
              id="isci4-6"
              v-model="firmEmployeInfo.supportStaffQualificationExpectation"
              placeholder="Nitelik Beklentisi"
              type="text"
              size="sm"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BFormGroup, BFormInput, BInputGroup, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import * as apiPBZ from '@/api/tanimlar/VKT/personelbulmazorlugu'
import * as apiPTY from '@/api/tanimlar/personelteminyontemi'
import * as apiPAN from '@/api/tanimlar/anlasamaneden'
// import * as apiFirmEmployeInfo from '@/api/islemler/FirmEmployementInfos'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BInputGroup,
    BRow,
    BCol,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    firmId: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  data() {
    return {
      optionsbulzor: [],
      optionsPTY: [],
      optionsPAN: [],
      iscisay: this.firmId,
      selWBZ: {
        staffFindingDifficultyDescription: 'Çok Kolay Bulubabilir',
        staffFindingDifficultyId: 1,
      },
      selWSM: {
        staffingMethodId: 1,
        staffingMethodDescription: 'Kendi web siteme ilan veririm.',
      },
      selWDR: {
        disagreementReasonId: 1,
        disagreementReasonDescription: 'Maaşların zamanında ödenmemesi',
      },
      selFBZ: {
        staffFindingDifficultyDescription: 'Çok Kolay Bulubabilir',
        staffFindingDifficultyId: 1,
      },
      selFSM: {
        staffingMethodId: 1,
        staffingMethodDescription: 'Kendi web siteme ilan veririm.',
      },
      selFDR: {
        disagreementReasonId: 1,
        disagreementReasonDescription: 'Maaşların zamanında ödenmemesi',
      },
      selVBZ: {
        staffFindingDifficultyDescription: 'Çok Kolay Bulubabilir',
        staffFindingDifficultyId: 1,
      },
      selVSM: {
        staffingMethodId: 1,
        staffingMethodDescription: 'Kendi web siteme ilan veririm.',
      },
      selVDR: {
        disagreementReasonId: 1,
        disagreementReasonDescription: 'Maaşların zamanında ödenmemesi',
      },
      selSBZ: {
        staffFindingDifficultyDescription: 'Çok Kolay Bulubabilir',
        staffFindingDifficultyId: 1,
      },
      selSSM: {
        staffingMethodId: 1,
        staffingMethodDescription: 'Kendi web siteme ilan veririm.',
      },
      selSDR: {
        disagreementReasonId: 1,
        disagreementReasonDescription: 'Maaşların zamanında ödenmemesi',
      },
    }
  },
  computed: {
    ...mapGetters({
      firmEmployeInfo: 'firmInventory/GET_FIRMEMPLOYEMENTINFO',
    }),
  },
  watch: {
    selWBZ() {
      this.firmEmployeInfo.workerFindingDifficultyId = this.selWBZ.staffFindingDifficultyId
    },
    selWSM() {
      this.firmEmployeInfo.workerStaffingMethodId = this.selWSM.staffingMethodId
    },
    selWDR() {
      this.firmEmployeInfo.workerDisagreementReasonId = this.selWDR.disagreementReasonId
    },
    selFBZ() {
      this.firmEmployeInfo.functionalSpecialistFindingDifficultyId = this.selFBZ.staffFindingDifficultyId
    },
    selFSM() {
      this.firmEmployeInfo.functionalSpecialistStaffingMethodId = this.selFSM.staffingMethodId
    },
    selFDR() {
      this.firmEmployeInfo.functionalSpecialistDisagreementReasonId = this.selFDR.disagreementReasonId
    },
    selVBZ() {
      this.firmEmployeInfo.vocationalSpecialistFindingDifficultyId = this.selVBZ.staffFindingDifficultyId
    },
    selVSM() {
      this.firmEmployeInfo.vocationalSpecialistStaffingMethodId = this.selVSM.staffingMethodId
    },
    selVDR() {
      this.firmEmployeInfo.vocationalSpecialistDisagreementReasonId = this.selVDR.disagreementReasonId
    },
    selSBZ() {
      this.firmEmployeInfo.supportStaffFindingDifficultyId = this.selSBZ.staffFindingDifficultyId
    },
    selSSM() {
      this.firmEmployeInfo.supportStaffStaffingMethodId = this.selSSM.staffingMethodId
    },
    selSDR() {
      this.firmEmployeInfo.supportStaffDisagreementReasonId = this.selSDR.disagreementReasonId
    },
    firmId() {
      this.getIsthidamByFirmId()
    },
  },
  mounted() {
    this.getPersonelBulmaZorluguList()
    this.getPersonelTeminYontemiList()
    this.GetAnlasamamaNedenleriList()
    this.getIsthidamByFirmId()
  },
  methods: {
    ...mapActions({
      fetchFirmEmployeInfo: 'firmInventory/action_get_firmEmployementInfo',
    }),
    ...mapMutations({
      setFirmEmplInfo: 'firmInventory/SET_FIRMEMPLOYEMENTINFO',
    }),
    getPersonelBulmaZorluguList() {
      apiPBZ.getPersonelBulmaZorlugu().then(res => {
        this.optionsbulzor = res.data.list
      })
    },
    getPersonelTeminYontemiList() {
      apiPTY.GetPersonelTeminYontemi().then(secs => {
        this.optionsPTY = secs.data.list
      })
    },
    GetAnlasamamaNedenleriList() {
      apiPAN.GetAnlasamamaNedenleri().then(res => {
        this.optionsPAN = res.data.list
      })
    },
    getIsthidamByFirmId() {
      this.fetchFirmEmployeInfo(this.firmId).then(() => {
        this.selWBZ = {
          staffFindingDifficultyDescription: this.firmEmployeInfo.workerFindingDifficulty?.staffFindingDifficultyDescription,
          staffFindingDifficultyId: this.firmEmployeInfo.workerFindingDifficulty?.staffFindingDifficultyId,
        }
        this.selWSM = {
          staffingMethodId: this.firmEmployeInfo.workerStaffingMethod?.staffingMethodId,
          staffingMethodDescription: this.firmEmployeInfo.workerStaffingMethod?.staffingMethodDescription,
        }
        this.selWDR = {
          disagreementReasonId: this.firmEmployeInfo.workerDisagreementReason?.disagreementReasonId,
          disagreementReasonDescription: this.firmEmployeInfo.workerDisagreementReason?.disagreementReasonDescription,
        }
        this.selFBZ = {
          staffFindingDifficultyDescription: this.firmEmployeInfo.functionalSpecialistFindingDifficulty?.staffFindingDifficultyDescription,
          staffFindingDifficultyId: this.firmEmployeInfo.functionalSpecialistFindingDifficulty?.staffFindingDifficultyId,
        }
        this.selFSM = {
          staffingMethodId: this.firmEmployeInfo.functionalSpecialistStaffingMethod?.staffingMethodId,
          staffingMethodDescription: this.firmEmployeInfo.functionalSpecialistStaffingMethod?.staffingMethodDescription,
        }
        this.selFDR = {
          disagreementReasonId: this.firmEmployeInfo.functionalSpecialistDisagreementReason?.disagreementReasonId,
          disagreementReasonDescription: this.firmEmployeInfo.functionalSpecialistDisagreementReason?.disagreementReasonDescription,
        }
        this.selVBZ = {
          staffFindingDifficultyDescription: this.firmEmployeInfo.vocationalSpecialistFindingDifficulty?.staffFindingDifficultyDescription,
          staffFindingDifficultyId: this.firmEmployeInfo.vocationalSpecialistFindingDifficulty?.staffFindingDifficultyId,
        }
        this.selVSM = {
          staffingMethodId: this.firmEmployeInfo.vocationalSpecialistStaffingMethod?.staffingMethodId,
          staffingMethodDescription: this.firmEmployeInfo.vocationalSpecialistStaffingMethod?.staffingMethodDescription,
        }
        this.selVDR = {
          disagreementReasonId: this.firmEmployeInfo.vocationalSpecialistDisagreementReason?.disagreementReasonId,
          disagreementReasonDescription: this.firmEmployeInfo.vocationalSpecialistDisagreementReason?.disagreementReasonDescription,
        }
        this.selSBZ = {
          staffFindingDifficultyDescription: this.firmEmployeInfo.supportStaffFindingDifficulty?.staffFindingDifficultyDescription,
          staffFindingDifficultyId: this.firmEmployeInfo.supportStaffFindingDifficulty?.staffFindingDifficultyId,
        }
        this.selSSM = {
          staffingMethodId: this.firmEmployeInfo.supportStaffStaffingMethod?.staffingMethodId,
          staffingMethodDescription: this.firmEmployeInfo.supportStaffStaffingMethod?.staffingMethodDescription,
        }
        this.selSDR = {
          disagreementReasonId: this.firmEmployeInfo.supportStaffDisagreementReason?.disagreementReasonId,
          disagreementReasonDescription: this.firmEmployeInfo.supportStaffDisagreementReason?.disagreementReasonDescription,
        }
      })
    },
  },
}
</script>

<style lang='scss'>
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
