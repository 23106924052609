import request from '@/utils/request'

export function GetPersonelTeminYontemi() {
  return request({
    url: '/api/StaffingMethod/GetStaffingMethods',
    method: 'get',
  })
}

export function AddPersonelTeminYontemi(data) {
  return request({
    url: '/api/StaffingMethod/AddStaffingMethod',
    method: 'post',
    data,
  })
}

export function UpdatePersonelTeminYontemi(data) {
  return request({
    url: '/api/StaffingMethod/UpdateStaffingMethod',
    method: 'put',
    data,
  })
}

export function deletePersonelTeminYontemi(data) {
  return request({
    url: '/api/StaffingMethod/DeleteStaffingMethod',
    method: 'delete',
    data,
  })
}
