<template>
  <div>
    <!-- Ekle Butonu ve Arama Kutusu  -->
    <b-row>
      <b-col
        cols="6"
        md="2"
      >
        <div id="adres-grid-container">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.adressadd-modal
            class="d-flex align-items-center"
            squared
            variant="secondary"
            :disabled="firmId<1"
            size="large"
          >
            <feather-icon
              icon="PlusCircleIcon"
              class="mr-50"
            />
            <span>Adres Ekle</span>
          </b-button>
        </div>
      </b-col>
    </b-row>
    <br>
    <!-- Adresler Tablosu -->
    <vue-good-table
      :columns="coladres"
      :rows="adresler"
      :search-options="{
        enabled: true,
        placeholder:'Adres Ara'}"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      theme="my-theme"
      @on-row-click="onDataGridItemClicked"
    >
      <div slot="emptystate">
        <center>  Adres kaydı bulunamadı </center>
      </div>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span>
            <template>
              <b-button-group
                class="mt-10"
                size="sm"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  size="sm"
                  class="mr-1"
                  squared
                  variant="outline-warning"
                  @click="onUpdateAdresBtnClicked(props.row)"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Düzenle</span>
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  size="sm"
                  squared
                  variant="outline-danger"
                  @click="onDeleteAdresBtnClicked(props.row)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Sil</span>
                </b-button>
              </b-button-group>
            </template>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              1 ila
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','20','50','100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> {{ props.total }} kayıt gösteriliyor </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <!-- Adres Ekle MODAL -->
    <b-modal
      id="adressadd-modal"
      cancel-variant="outline-secondary"
      ok-title="Ekle"
      cancel-title="İptal"
      size="lg"
      centered
      no-close-on-backdrop
      title="Adres Ekleme"
      @ok="onModalSaveAdres"
      @cancel="onModalCancelled"
    >
      <b-form>
        <b-row>
          <b-col>
            <b-form-group
              label="Adres Tipi:"
              label-for="AddressTypes"
            >
              <v-select
                id="AddressTypes"
                v-model="firmAddressTypes"
                label="addressTypeDescription"
                :options="AdresTipleri"
                size="sm"
                class="mt-1"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="İl seçniz:"
              label-for="cities"
            >
              <v-select
                id="cities"
                v-model="selectedCity"
                label="cityName"
                :options="cityList"
                size="sm"
                class="mt-1"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="İlçe seçiniz:"
              label-for="towns"
            >
              <v-select
                id="towns"
                v-model="towns"
                label="townName"
                :options="townList"
                size="sm"
                class="mt-1"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Mahalle seçniz:"
              label-for="disricts"
            >
              <v-select
                id="districts"
                v-model="districts"
                label="neighborhoodName"
                :options="districtList"
                size="sm"
                class="mt-1"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Ana Cadde / Bulvar  :"
              label-for="mainStreet"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="MStreet"
                  v-model="mainStreet"
                  placeholder="Ana Cadde / Bulvar"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Sokak :"
              label-for="street"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="Sokak"
                  v-model="street"
                  placeholder="Sokak"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Kapı Daire Numarası:"
              label-for="DoorNmb"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="DoorNmb"
                  v-model="doorNumber"
                  placeholder="Kapı Daire Numarası"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Açık Adres :"
              label-for="AdresDesc"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="AdresDesc"
                  v-model="fullAddress"
                  placeholder="Açık Adres "
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <b-modal
      id="adresguncel-modal"
      cancel-variant="outline-danger"
      ok-title="Güncelle"
      cancel-title="İptal"
      centered
      no-close-on-backdrop
      title="Adres güncelle"
      @ok="onModalUpdateAdres"
      @cancel="onModalCancelled"
    >
      <b-form>
        <b-row>
          <b-col>
            <b-form-group
              label="Adres Tipi:"
              label-for="AddressTypes"
            >
              <v-select
                id="AddressTypes"
                v-model="firmAddressTypes"
                label="addressTypeDescription"
                :options="AdresTipleri"
                size="sm"
                class="mt-1"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="İl seçniz:"
              label-for="cities"
            >
              <v-select
                id="cities"
                v-model="selectedCity"
                label="cityName"
                :options="cityList"
                size="sm"
                class="mt-1"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="İlçe seçiniz:"
              label-for="towns"
            >
              <v-select
                id="towns"
                v-model="towns"
                label="townName"
                :options="townList"
                size="sm"
                class="mt-1"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Mahalle seçniz:"
              label-for="disricts"
            >
              <v-select
                id="districts"
                v-model="districts"
                label="neighborhoodName"
                :options="districtList"
                size="sm"
                class="mt-1"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Ana Cadde / Bulvar  :"
              label-for="mainStreet"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="MStreet"
                  v-model="mainStreet"
                  placeholder="Ana Cadde / Bulvar"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Sokak :"
              label-for="street"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="Sokak"
                  v-model="street"
                  placeholder="Sokak"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Kapı Daire Numarası:"
              label-for="DoorNmb"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="DoorNmb"
                  v-model="doorNumber"
                  placeholder="Kapı Daire Numarası"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Açık Adres :"
              label-for="AdresDesc"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="AdresDesc"
                  v-model="fullAddress"
                  placeholder="Açık Adres "
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-input
          id="AdrId"
          v-model="firmAddressId"
          disabled
          hidden
        />
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import * as apiAdres from '@/api/islemler/firmAdres'
import * as apiAdresTip from '@/api/tanimlar/VKT/addressTypes'
import * as apiCities from '@/api/tanimlar/sehir'
import * as apiTowns from '@/api/tanimlar/town'
import * as apiMahalle from '@/api/tanimlar/Neighborhood'
import Ripple from 'vue-ripple-directive'
import {
  BFormInput, BButton, BCol, BRow, BFormGroup, BForm, BModal, VBModal, BFormSelect, BButtonGroup, BPagination, BInputGroup,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VueGoodTable,
    vSelect,
    BModal,
    BButton,
    BFormInput,
    BCol,
    BRow,
    BFormGroup,
    BForm,
    BFormSelect,
    BButtonGroup,
    BPagination,
    BInputGroup,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  props: {
    firmId: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  data() {
    return {
      coladres: [
        {
          label: 'Adres Tipi',
          field: 'firmAddressType.addressTypeDescription',
        },
        {
          label: 'İli',
          field: 'city.cityName',
        },
        {
          label: 'İlçesi',
          field: 'town.townName',
        },
        {
          label: 'Mahalle',
          field: 'neighborhood.neighborhoodName',
        },
        {
          label: 'Cadde',
          field: 'mainStreet',
        },
        {
          label: 'Sokak',
          field: 'street',
        },
        {
          label: 'İşlem',
          field: 'action',
          sortable: false,
          width: '15%',
        },
      ],
      adresler: [],
      firmAddressTypes: { addressTypeId: -1, addressTypeDescription: 'Adres tipi seçiniz' },
      firmAddressId: 0,
      AdresTipleri: [],
      selectedCity: {
        cityId: 1,
        cityName: 'Adana',
        plateCode: '01',
        countryId: 1,
      },
      cityList: [],
      townList: [],
      towns: {
        townId: -1,
        townName: 'İlçe Seçiniz',
        cityId: -1,
      },
      districts: { neighborhoodId: -1, neighborhoodName: 'Mahalle seçiniz', townId: -1 },
      districtList: [],
      mainStreet: '',
      fullAddress: '',
      street: '',
      doorNumber: '',
      searchTerm: '',
      pageLength: 15,
    }
  },
  watch: {
    selectedCity() {
      this.gettowns()
    },
    towns() {
      this.getMahalle()
    },
    firmId() {
      this.getfirmAdresList()
    },
  },
  created() {
    apiAdres.GetFirmAddressByFirmId(this.firmId).then(res => {
      this.adresler = res.data.list
    })
  },
  mounted() {
    this.getfirmAdresList()
    this.getAdresTipList()
    this.getcities()
    this.gettowns()
  },
  methods: {
    getfirmAdresList() {
      apiAdres.GetFirmAddressByFirmId(this.firmId).then(res => {
        this.adresler = res.data.list
      })
    },
    getAdresTipList() {
      apiAdresTip.GetAddressTypes().then(res => {
        this.AdresTipleri = res.data.list
      })
    },
    getcities() {
      apiCities.getCityByCountryId(1).then(res => {
        this.cityList = res.data.list
      })
    },
    gettowns() {
      apiTowns.GetTownsByCityId(this.selectedCity.cityId).then(res => {
        this.townList = res.data.list
      })
    },
    getMahalle() {
      apiMahalle.getNeighborhoodsByTownId(this.towns.townId).then(res => {
        this.districtList = res.data.list
      })
    },
    onDataGridItemClicked(params) {
      return params
    },
    clearModal() {
      this.firmAddressTypes = { addressTypeId: -1, addressTypeDescription: 'Adres tipi seçiniz' }
      // eslint-disable-next-line object-curly-newline
      this.selectedCity = { cityId: 1, cityName: 'Adana', plateCode: '01', countryId: 1 }
      this.towns = { townId: -1, townName: 'İlçe Seçiniz', cityId: -1 }
      this.districts = { neighborhoodId: -1, neighborhoodName: 'Mahalle seçiniz', townId: -1 }
      this.mainStreet = ''
      this.street = ''
      this.doorNumber = ''
      this.fullAddress = ''
    },
    onModalSaveAdres() {
      const adresModel = {
        firmAddressId: 0,
        firmInfoId: this.firmId,
        firmAddressTypeId: this.firmAddressTypes.addressTypeId,
        cityId: this.selectedCity.cityId,
        townId: this.towns.townId,
        neighborhoodId: this.districts.neighborhoodId,
        mainStreet: this.mainStreet,
        street: this.street,
        doorNumber: this.doorNumber,
        fullAddress: this.fullAddress,
      }
      apiAdres.AddFirmAddress(adresModel).then(() => {
        this.getfirmAdresList()
      })
      this.clearModal()
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Adres ekle',
          text: 'Yeni kayıt eklendi.',
          icon: 'PlusSquareIcon',
          variant: 'success',
        },
      })
      this.getfirmAdresList()
    },
    onModalCancelled() {
      this.clearModal()
    },
    onUpdateAdresBtnClicked(row) {
      this.firmAddressId = row.firmAddressId
      this.firmAddressTypes = row.firmAddressType
      this.selectedCity = row.city
      this.towns = row.town
      this.districts = row.neighborhood
      this.mainStreet = row.mainStreet
      this.street = row.street
      this.doorNumber = row.doorNumber
      this.fullAddress = row.fullAddress
      this.$bvModal.show('adresguncel-modal')
    },
    onModalUpdateAdres() {
      const adresModel = {
        firmAddressId: this.firmAddressId,
        firmInfoId: this.firmId,
        firmAddressTypeId: this.firmAddressTypes.addressTypeId,
        cityId: this.selectedCity.cityId,
        townId: this.towns.townId,
        neighborhoodId: this.districts.neighborhoodId,
        mainStreet: this.mainStreet,
        street: this.street,
        doorNumber: this.doorNumber,
        fullAddress: this.fullAddress,
      }
      apiAdres.UpdateFirmAddress(adresModel).then(() => {
        this.getfirmAdresList()
      })
      this.clearModal()
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Adres Güncelleme',
          text: 'Kayıt başarıyla güncellendi.',
          icon: 'EditIcon',
          variant: 'warning',
        },
      })
      this.getfirmAdresList()
    },
    onDeleteAdresBtnClicked(row) {
      this.$bvModal.msgBoxConfirm(` '${row.firmAddressType.addressTypeDescription}' adres tanımı silinecek, onaylıyor musunuz?`, {
        title: 'Uyarı!',
        size: 'md',
        buttonSize: 'md',
        okVariant: 'danger',
        okTitle: 'Evet',
        cancelTitle: 'Hayır',
        footerClass: 'p-2',
        hideHeaderClose: true,
        noCloseOnBackdrop: true,
        centered: true,
      })
        .then(value => {
          if (value) {
            const AdresId = row.firmAddressId
            apiAdres.DeleteFirmAddress(AdresId).then(() => {
              this.getfirmAdresList()
            })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Kayıt Silindi',
                text: `${row.firmAddressType.addressTypeDescription} kaydı silindi.`,
                icon: 'DeleteIcon',
                variant: 'danger',
              },
            })
          }
        })
      this.clearModal()
    },
  },
}
</script>
<style lang='scss'>
   @import '@core/scss/vue/libs/vue-select.scss';
   @import '@core/scss/vue/libs/vue-good-table.scss';
</style>
